import { Box, Tooltip } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';

interface TimeAgoProps {
    timestamp: string;
    updateInterval?: number;
    showDate?: boolean;
}

export function TimeAgo({ timestamp, updateInterval = 60000, showDate = false }: TimeAgoProps) {
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        const updateTimeAgo = () => {
            const time = moment(timestamp);
            setTimeAgo(time.fromNow());
        };

        updateTimeAgo();
        const interval = setInterval(updateTimeAgo, updateInterval);

        return () => clearInterval(interval);
    }, [timestamp, updateInterval]);

    const formattedDate = moment(timestamp).format('DD/MM/YYYY');

    return (
        <Tooltip title={moment(timestamp).format('MMMM Do YYYY, h:mm:ss a')}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Box sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                    {timeAgo}
                    {showDate && ` (${formattedDate})`}
                </Box>
            </Box>
        </Tooltip>
    );
} 