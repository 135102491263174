import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Box, Chip, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DBSessionEventsThunks, sessionEventsSelectors } from '../../reducers/DBServiceThunks';
import { useAppDispatch, useAppSelector } from '../../reducers/hooks/store.hook';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { selectSessionEventsByUserId, selectGroupedSessionEvents, selectPaginationStatus } from './session-timeline.selectors';

const ITEMS_PER_PAGE = 20;

interface SessionEvent {
  _id: string;
  owner: string;
  timestamp: string;
  event: string;
  details?: {
    podId?: string;
  };
  podId?: string;
  sessionId?: string;
}

interface GroupedEvents {
  [key: string]: {
    latestEvent: SessionEvent;
    events: SessionEvent[];
  };
}

const getEventColor = (event: string): 'info' | 'secondary' | 'success' | 'primary' | 'warning' | 'error' | 'inherit' | 'grey' => {
  // Session creation events
  if (event.startsWith('session_')) {
    switch (event) {
      case 'session_request': return 'info';
      case 'session_exists': return 'secondary';
      case 'session_created': return 'success';
      case 'session_assigned': return 'primary';
      case 'session_cleanup': return 'warning';
      case 'session_removed': return 'error';
      case 'session_error': return 'error';
      default: return 'grey';
    }
  }
  
  // Browser events
  if (event.startsWith('browser_')) {
    switch (event) {
      case 'browser_starting': return 'info';
      case 'browser_started': return 'success';
      case 'browser_closed': return 'warning';
      default: return 'grey';
    }
  }

  // Navigation events
  if (event.startsWith('navigation_')) {
    switch (event) {
      case 'navigation_started': return 'info';
      case 'navigation_complete': return 'success';
      case 'navigation_failed': return 'error';
      default: return 'grey';
    }
  }

  // WhatsApp events
  switch (event) {
    case 'qr_generated': return 'info';
    case 'qr_timeout': return 'warning';
    case 'authenticated': return 'success';
    case 'store_ready': return 'success';
    case 'connection_error': return 'error';
    case 'disconnected': return 'warning';
    default: return 'grey';
  }
};

const getEventIcon = (event: string) => {
  // You can add custom icons for different event types
  return null; // For now, using default dots
};

const formatEventTitle = (event: string, details: any) => {
  // Format the event type for display
  const eventType = details?.type ? details.type.replace(/_/g, ' ') : null;
  const eventName = event.replace(/_/g, ' ').replace('created', '').trim();
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
        {eventName}
      </Typography>
      {eventType && (
        <Chip 
          label={eventType} 
          size="small"
          color="primary"
          variant="outlined"
          sx={{ textTransform: 'capitalize' }}
        />
      )}
    </Box>
  );
};

const formatDetails = (details: any) => {
  if (!details) return null;

  // Remove type from details since we show it in title
  const { type, ...otherDetails } = details;

  const formatValue = (value: any): string => {
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (typeof value === 'object') return JSON.stringify(value, null, 2);
    return String(value);
  };

  if (Object.keys(otherDetails).length === 0) return null;

  return (
    <Box sx={{ mt: 1 }}>
      {Object.entries(otherDetails).map(([key, value]) => (
        <Box key={key} sx={{ display: 'flex', gap: 1, mb: 0.5 }}>
          <Chip 
            label={key.replace(/_/g, ' ')} 
            size="small" 
            variant="outlined"
          />
          <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
            {formatValue(value)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const formatTimestamp = (timestamp: string | Date): string => {
  const date = new Date(timestamp);
  return date.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });
};

// Add this helper function to format the pod ID
const formatPodId = (podId: string): string => {
  const parts = podId.split('-');
  return parts[parts.length - 1] || podId;
};

// Add this helper function for duration formatting
const formatDuration = (events: any[]) => {
  if (!events || events.length === 0) return null;
  
  const sortedEvents = [...events].sort((a, b) => 
    new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );
  
  const firstEvent = moment(sortedEvents[0].timestamp);
  const lastEvent = moment(sortedEvents[sortedEvents.length - 1].timestamp);
  const duration = moment.duration(lastEvent.diff(firstEvent));
  
  return {
    from: firstEvent.format('DD/MM/YYYY HH:mm:ss'),
    to: lastEvent.format('DD/MM/YYYY HH:mm:ss'),
    duration: duration.humanize()
  };
};

export const SessionTimeline: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [skip, setSkip] = React.useState(0);
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  
  const { userId } = useParams<{ userId: string }>();
  
  const events = useAppSelector((state) => 
    selectSessionEventsByUserId(state, userId)
  ).sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  
  const groupedEvents = useAppSelector((state) => 
    selectGroupedSessionEvents(state, userId)
  );
  
  const { itemsLoading, total } = useAppSelector(selectPaginationStatus);

  const loadMore = React.useCallback(async () => {
    if (!itemsLoading && userId) {
      try {
        await dispatch(DBSessionEventsThunks.find({
          $sort: { timestamp: -1 },
          $skip: skip,
          $limit: ITEMS_PER_PAGE,
          $search: searchTerm,
          owner: userId,
          $select: ['event', 'timestamp', 'details', 'sessionId', 'podId', 'owner']
        })).unwrap();
        setSkip(prev => prev + ITEMS_PER_PAGE);
      } catch (error) {
        console.error('Error loading more events:', error);
      }
    }
  }, [dispatch, userId, skip, searchTerm, itemsLoading]);

  const refresh = React.useCallback(() => {
    setSkip(0);
    dispatch(DBSessionEventsThunks.find({
      $sort: { timestamp: -1 },
      $skip: 0,
      $limit: ITEMS_PER_PAGE,
      $search: searchTerm,
      owner: userId,
      $select: ['event', 'timestamp', 'details', 'sessionId', 'podId', 'owner']
    }));
  }, [dispatch, userId, searchTerm]);

  const debouncedSearch = React.useMemo(
    () => 
      debounce((term: string) => {
        setSkip(0);
        dispatch(DBSessionEventsThunks.find({
          $sort: { timestamp: -1 },
          $skip: 0,
          $limit: ITEMS_PER_PAGE,
          $search: term,
          owner: userId,
          $select: ['event', 'timestamp', 'details', 'sessionId', 'podId', 'owner']
        }));
      }, 500),
    [dispatch, userId]
  );

  React.useEffect(() => {
    if (isInitialLoad && userId) {
      loadMore().then(() => {
        setIsInitialLoad(false);
      });
    }
  }, [userId, isInitialLoad, loadMore]);

  if (isInitialLoad && !events.length) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 800, margin: '0 auto' }}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Session Timeline
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search events..."
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            debouncedSearch(e.target.value);
          }}
          sx={{ mr: 2 }}
        />
        <IconButton onClick={refresh}>
          <Refresh />
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, mb: 2, color: 'text.secondary', fontSize: '0.875rem' }}>
        <Typography>Events: {events.length}</Typography>
        <Typography>•</Typography>
        <Typography>Groups: {Object.keys(groupedEvents).length}</Typography>
        <Typography>•</Typography>
        <Typography>Total: {total}</Typography>
        <Typography>•</Typography>
        <Typography>Skip: {skip}</Typography>
      </Box>

      <InfiniteScroll
        dataLength={events.length}
        next={loadMore}
        hasMore={events.length < total}
        loader={
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress size={24} />
          </Box>
        }
        scrollThreshold={0.8}
      >
        {Object.entries(groupedEvents)
          .sort(([, a], [, b]) => 
            new Date(b.latestEvent.timestamp).getTime() - new Date(a.latestEvent.timestamp).getTime()
          )
          .map(([podId, { latestEvent, events: podEvents }]) => (
            <Accordion key={podId} sx={{ mb: 2 }}>
              <AccordionSummary 
                expandIcon={<ExpandMore />}
                sx={{ 
                  '& .MuiAccordionSummary-content': { 
                    display: 'flex', 
                    flexDirection: 'column',
                    gap: 1
                  }
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 2,
                  flexWrap: 'wrap'
                }}>
                  <Chip 
                    label={`Pod: ${formatPodId(podId)}`}
                    size="small"
                    color="primary"
                  />
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1,
                    flex: 1,
                    minWidth: 0
                  }}>
                    <TimelineDot 
                      color={getEventColor(latestEvent.event)} 
                      sx={{ my: 0, flexShrink: 0 }}
                    />
                    {formatEventTitle(latestEvent.event, latestEvent.details)}
                  </Box>
                  <Typography 
                    variant="caption" 
                    color="textSecondary" 
                    sx={{ flexShrink: 0 }}
                  >
                    {formatTimestamp(latestEvent.timestamp)}
                  </Typography>
                </Box>
                
                {/* Add duration info */}
                {podEvents.length > 0 && (
                  <Box sx={{ 
                    display: 'flex', 
                    gap: 2, 
                    alignItems: 'center',
                    pl: 1,
                    opacity: 0.8
                  }}>
                    {(() => {
                      const timing = formatDuration(podEvents);
                      return timing ? (
                        <>
                          <Typography variant="caption" color="textSecondary">
                            From: {timing.from}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            To: {timing.to}
                          </Typography>
                          <Typography 
                            variant="caption" 
                            color="textSecondary"
                            sx={{ 
                              ml: 'auto',
                              fontStyle: 'italic'
                            }}
                          >
                            Duration: {timing.duration}
                          </Typography>
                        </>
                      ) : null;
                    })()}
                  </Box>
                )}
              </AccordionSummary>
              
              <AccordionDetails>
                <Timeline>
                  {podEvents
                    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
                    .map((event, index) => (
                      <TimelineItem key={event._id}>
                        <TimelineSeparator>
                          <TimelineDot color={getEventColor(event.event)}>
                            {getEventIcon(event.event)}
                          </TimelineDot>
                          {index < podEvents.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                          {formatEventTitle(event.event, event.details)}
                          <Typography variant="caption" color="textSecondary" display="block">
                            {formatTimestamp(event.timestamp)}
                          </Typography>
                          {event.details && formatDetails(event.details)}
                          {event.sessionId && (
                            <Chip 
                              label={`Session ID: ${event.sessionId}`}
                              size="small"
                              sx={{ mt: 1 }}
                            />
                          )}
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                </Timeline>
              </AccordionDetails>
            </Accordion>
          ))}
      </InfiniteScroll>
    </Box>
  );
}; 