import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { DBUserThunks } from '@common-reducers/DBServiceThunks';
import { isEmail } from 'validator';

interface UpdateEmailActionProps {
    userId: string;
    currentEmail?: string;
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    onError: (message: string) => void;
}

export function UpdateEmailAction({ userId, currentEmail, open, onClose, onSuccess, onError }: UpdateEmailActionProps) {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const dispatch = useAppDispatch();

    const handleClose = () => {
        setEmail('');
        setEmailError('');
        onClose();
    };

    const handleSubmit = async () => {
        if (!isEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        try {
            await dispatch(DBUserThunks.patch({
                entity: {
                    _id: userId,
                    email
                }
            }));
            onSuccess();
            handleClose();
        } catch (error) {
            onError('Failed to update email');
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Update Email</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError('');
                    }}
                    error={!!emailError}
                    helperText={emailError}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained">Update</Button>
            </DialogActions>
        </Dialog>
    );
} 