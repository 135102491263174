import { useSendResetPasswordMutation } from "@common-reducers/backend-api/backend-api";
import React, { useEffect, useState } from "react";
import classes from "./forgot-password.form.module.scss";
import accessFormclasses from "../access-form.module.scss";
import BTButton from "@common-components/bt-button/bt-button";
import FormPatial from "../form-patial";
import FormHeader from "../form-header/header";
import Input from "../form-input/form-input";
import { Box } from "@mui/system";
import { mainSliceActions } from "@common-reducers/MainReducer";
import { useAppDispatch } from "@common-reducers/hooks/store.hook";
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@common-services/analytics-service";
import { useTranslation } from "react-i18next";
import { namespace } from "@common-config/constants";

export interface ForgotPasswordFormState {
  email: string;
  loading: boolean;
  status: string;
}

const STATUS_INITIAL = "";
const STATUS_EMAIL_SENT = "EMAIL_SENT";

export const ForgotPasswordForm = () => {
  const { t } = useTranslation(namespace);
  const analyticsService: AnalyticsService = useAnalyticsService();
  const dispatch = useAppDispatch();
  const [sendForgotPasswordLink] = useSendResetPasswordMutation();
  const [isErr, setIsErr] = useState<boolean>(false);
  const [state, setState] = useState<ForgotPasswordFormState>({
    email: "",
    loading: false,
    status: STATUS_INITIAL,
  });

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, email: event.target.value });
  };

  const sendResetLink = async () => {
    if (!state.email) {
      setIsErr(true);
      return;
    }
    setIsErr(false);
    setState({ ...state, loading: true });

    try {
      const res = await sendForgotPasswordLink(state.email).unwrap();
      setState({ ...state, loading: false, status: STATUS_EMAIL_SENT });
    } catch (e) {
      console.log('Problem sending reset password');
      setState({ ...state, loading: false });
    }
  };

  const handleFormState = (target: string) => {
    dispatch(mainSliceActions.setFormState(target));
  };

  let imagePath = "/img/forgot_password_illustrator.png";
  let text = t('forgotPassword.insertEmail');

  if (state.status === STATUS_EMAIL_SENT) {
    imagePath = "/img/email_sent_illustrator.png";
    text = t('forgotPassword.followInstructions');
  }

  useEffect(() => {
    document.title = t('forgotPassword.title');
  }, [t]);

  return (
    <FormPatial imagePath={imagePath}>
      <div className={accessFormclasses.FormContainer}>
        <Box sx={{ marginBottom: "10px" }}><FormHeader text={text} /></Box>
        <form className={classes.form}>
          {state.status !== STATUS_EMAIL_SENT && (
            <Input
              value={state.email}
              onChange={onEmailChange}
              style={{ borderColor: isErr && !state.email ? '#f72371' : '' }}
              placeholder={t('forgotPassword.yourEmail')}
              errorMessage={isErr && !state.email ? t('forgotPassword.emailIsRequired') : ''}
            />
          )}
          <Box className={classes.FormFooter}>
            <BTButton
              disabled={state.loading}
              loading={state.loading}
              sx={{ width: 1, padding: '15px 0' }}
              onClick={() => {
                analyticsService.event("send_email_password_link", {
                  category: "access",
                  action: "send email password link",
                });
                sendResetLink();
              }}
            >
              {state.status === STATUS_EMAIL_SENT ? 'Resend Password Reset Email' : t('forgotPassword.sendEmail')}
            </BTButton>
            <Box
              className={classes.FormLabelText}
              style={{ marginTop: 16, cursor: 'pointer' }}
            >
              <span onClick={() => handleFormState('')}>
                {t('forgotPassword.backTo')} <span className={classes.ButtonLink}>{t('forgotPassword.login')}</span> {t('forgotPassword.page')}
              </span>
            </Box>
          </Box>
        </form>
      </div>
    </FormPatial>
  );
};

export default ForgotPasswordForm;
