import { Stack, Typography, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from 'react';

interface CampaignIdCellProps {
    campaignId?: string;
}

export const CampaignIdCell = ({ campaignId }: CampaignIdCellProps) => {
    const [copiedId, setCopiedId] = useState<string | null>(null);

    const handleCopy = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (campaignId) {
            navigator.clipboard.writeText(campaignId);
            setCopiedId(campaignId);
            setTimeout(() => setCopiedId(null), 2000);
        }
    };

    if (!campaignId) return null;

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Typography
                variant="body2"
                sx={{
                    fontFamily: 'monospace',
                    fontWeight: 500,
                    fontSize: '0.75rem'
                }}
            >
                {campaignId}
            </Typography>
            <Tooltip title={copiedId === campaignId ? "Copied!" : "Copy Campaign ID"}>
                <IconButton
                    size="small"
                    onClick={handleCopy}
                    color={copiedId === campaignId ? "success" : "default"}
                >
                    <ContentCopyIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}; 