import { IconButton, Menu, MenuItem, Snackbar, Alert } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { UpdateEmailAction } from './user-actions/update-email-action';
import { GetCheckoutPageUrl } from './user-actions/get-checkout-page-url';

interface UserActionsMenuProps {
    userId: string;
    currentEmail?: string;
}

export function UserActionsMenu({ userId, currentEmail }: UserActionsMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openEmailDialog, setOpenEmailDialog] = useState(false);
    const [openCheckoutDialog, setOpenCheckoutDialog] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUpdateEmail = () => {
        setOpenEmailDialog(true);
        handleClose();
    };

    const handleGetCheckoutPage = () => {
        setOpenCheckoutDialog(true);
        handleClose();
    };

    const handleSuccess = (message: string) => {
        setSnackbar({ open: true, message, severity: 'success' });
    };

    const handleError = (message: string) => {
        setSnackbar({ open: true, message, severity: 'error' });
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleUpdateEmail}>Update Email</MenuItem>
                <MenuItem onClick={handleGetCheckoutPage}>Get Checkout Page</MenuItem>
            </Menu>

            <UpdateEmailAction
                userId={userId}
                currentEmail={currentEmail}
                open={openEmailDialog}
                onClose={() => setOpenEmailDialog(false)}
                onSuccess={() => handleSuccess('Email updated successfully')}
                onError={handleError}
            />

            <GetCheckoutPageUrl
                open={openCheckoutDialog}
                onClose={() => setOpenCheckoutDialog(false)}
                onError={handleError}
            />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert 
                    severity={snackbar.severity} 
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
} 