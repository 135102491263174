import { useEffect } from "react";
import { unstable_HistoryRouter as HistoryRouter, Navigate, Route, Routes } from "react-router-dom";
import { FormState } from "@common-components/access-form/access-form";
import BTThemeProvider from "@common-components/bt-theme-provider";
import GuardedRoute from "@common-components/guarded-route/guarded-route";
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { globalHistory } from "@common-services/global-history";
import AccountInvoiceView from "@common-views/account-invoice-view/account-invoice-view";
import AccountLangView from "@common-views/account-lang/account-lang";
import AccountNotificationsView from "@common-views/account-notification/account-notification";
import AccountSecurityView from "@common-views/account-security/account-security";
import AccountWorkspacesView from "@common-views/account-workspaces/account-workspaces";
import JoinBoard from "@common-views/join-board/join-board";
import JoinWorkSpace from "@common-views/join-workspace/join-workspace";
import { AnalyticsService } from "@common-services/analytics-service";
import AccountSubscriptionView from "@common-views/account-subscription-view/account-subscription-view";
import AccountView from "@common-views/account-view/account-view";
import ApiPlaygroundView from "@common-views/api-playground-view/api-playground-view";
import JoinTaskBoard from "@common-views/join-task-board/join-task-board";
import LoginView from "@common-views/login-view/login-view";
import ResetPasswordView from "@common-views/reset-password-view/reset-password-view";
import SubscriptionView from "@common-views/subscription-view/subscription-view";
import TaskView from "@common-views/task-view/task-view";
import ThankYouView from "@common-views/thank-you-view/thank-you-view";
import WebHookGuideView from "@common-views/webbook-guide-view/webbook-guide-view";
import InviteUserModal from "@common-components/invite/InviteUserModal";
import AdminAPIView from "@common-views/admin-api/admin-api";
import AdminBillingView from "@common-views/admin-billing/admin-billing";
import ForbiddenAccessView from "@common-views/forbidden-access-view/forbidden-access-view";
import FormPreviewView from "@common-views/form-preview/form-preview-view";
import InvoiceView from "@common-views/invoice-view/invoice-view";
import PageNotFound from "@common-views/page-not-found/page-not-found";
import PaymentThankYouPopup from "@common-components/payment-form/payment-thank-you-popup";
import NotificationsDrawer from "@common-components/tasks/extraData/notifications/notifications-drawers";
import QrView from "@common-views/qr-view/qr-view";
import UninstallView from "@common-views/uninstall-view/uninstall-view";
import UserManagementView from "@common-views/user-management/user-management";
import WorkspaceView from "@common-views/workspace-view/workspace-view";
import ConditionalWrapper from "@common-components/conditional-wrapper/ConditionalWrapper";
import TrialModal from "@common-components/modals/trial-modal";
import Support from "@common-views/support/support";
import { UnifiedAdminBilling } from "@common-views/unified-admin-billing/unified-admin-billing";
import WebHookGuideMake from "@common-views/webhook-guide-make/webbook-guide-make";
import WebHookGuideZapier from "@common-views/webhook-guide-zaiper/webbook-guide-zapier";
import CalendarView from "@common-views/calendar-view/calendar-view";
import { CommonRootState } from "@common/types/common-root-state-type";
import UserOverview from "@common-views/user-overview/user-overview";
import { SessionTimeline } from '@common-views/session-timeline/session-timeline';

import { injectPixels } from "@common/site/inject-pixels";

import "@common/site/site.module.scss";
import '@common/color-scheme.css';
import { SessionSupport } from "@common/views/support/session-support";

export default function WebsiteComponent() {
  const analyticsService: AnalyticsService = useAnalyticsService();

  useEffect(() => {
    injectPixels()
    analyticsService.pageView(window.location.pathname);
  }, []);

  const sitePages = () => {
    const supportedUserId = new URLSearchParams(window.location.search).get('supportedUserId');
    const readOnly = supportedUserId ? true : false;
    return (
      <BTThemeProvider>
        <HistoryRouter history={globalHistory}>
          <Routes>
            <Route path="/account" element={
              <GuardedRoute
                element={<AccountView readOnly={readOnly} />}
                auth={true}
              />
            } />
            <Route path="/account-subscription" element={
              <GuardedRoute
                element={<AccountSubscriptionView />}
                auth={true}
              />
            } />
            <Route path="/account-invoices" element={
              <GuardedRoute
                element={<AccountInvoiceView />}
                auth={true}
              />
            } />
            <Route
              path="/new-workspace"
              element={
                <GuardedRoute element={<WorkspaceView />} auth={true} />
              }
            />
            <Route
              path="/edit-workspace"
              element={
                <GuardedRoute element={<WorkspaceView editMode />} auth={true} />
              }
            />
            <Route
              path="/form-preview/:shareId"
              element={<FormPreviewView />}
            />
            <Route
              path="/admin-billing-old"
              element={
                <GuardedRoute element={<AdminBillingView readOnly={readOnly} />} auth={true} />
              }
            />
            <Route
              path="/user-management"
              element={
                <GuardedRoute element={<UserManagementView readOnly={readOnly} />} auth={true} />
              }
            />
            <Route
              path="/admin-billing"
              element={
                <GuardedRoute element={<UnifiedAdminBilling />} auth={true} />
              }
            />
            <Route
              path="/admin-api"
              element={
                <GuardedRoute element={<AdminAPIView />} auth={true} />
              }
            />
            <Route
              path="/login"
              element={
                <GuardedRoute
                  element={<LoginView initialState={FormState.Login} />}
                  redirect='/tasks'
                  auth={false}
                  redirectBack={false}
                />
              } />
            <Route path="/signup" element={
              <GuardedRoute
                element={<LoginView initialState={FormState.Signup} />}
                redirect='/tasks'
                auth={false}
              />
            } />
            <Route path="/fyp" element={
              <GuardedRoute
                element={<LoginView initialState={FormState.ForgotPassword} />}
                redirect='/tasks'
                auth={false}
              />
            } />
            <Route path="/pricing" element={
              <GuardedRoute
                element={<SubscriptionView />}
                auth={true}
              />
            } />
            <Route path="/tasks" element={
              <GuardedRoute
                element={<TaskView />}
                auth={true}
              />
            } />
            <Route path="/mywork" element={
              <GuardedRoute
                element={<TaskView />}
                auth={true}
              />
            } />

            <Route
              path="/reset-password"
              element={<ResetPasswordView />}
            />
            <Route
              path="/account-language"
              element={
                <GuardedRoute element={<AccountLangView />} auth={true} />
              }
            />
            <Route
              path="/forbidden-access"
              element={<ForbiddenAccessView />}
            />
            <Route
              path="/account-notification"
              element={
                <GuardedRoute
                  element={<AccountNotificationsView />}
                  auth={true}
                />
              }
            />
            <Route
              path="/account-security"
              element={
                <GuardedRoute element={<AccountSecurityView />} auth={true} />
              }
            />
            <Route
              path="/thank-you"
              element={<ThankYouView />}
            />
            <Route
              path="/account-workspace"
              element={
                <GuardedRoute
                  element={<AccountWorkspacesView readOnly={readOnly} />}
                  auth={true}
                />
              }
            />
            <Route
              path="/trialModal"
              element={<TrialModal />}
            />
            <Route
              path="/qr-view"
              element={
                <GuardedRoute
                  element={<QrView />}
                  auth={true}
                />
              }
            />
            <Route
              path="/support"
              element={<GuardedRoute element={<Support />} auth={true} />}
            />
            <Route
              path="/notifications-drawer"
              element={
                <GuardedRoute element={<NotificationsDrawer />} auth={true} role="admin" />
              }
            />
            <Route path="/api-playground" element={<ApiPlaygroundView />} />
            <Route path="/webhook-guide" element={<WebHookGuideView />} />
            <Route path="/webhook-make-guide" element={<WebHookGuideMake />} />
            <Route path="/webhook-zapier-guide" element={<WebHookGuideZapier />} />
            <Route path="/thank-you" element={<ThankYouView />} />
            <Route path="/uninstall" element={<UninstallView />} />
            <Route path="/sharetable" element={<InviteUserModal />} />
            <Route
              path="/join-task-board/:shareId"
              element={
                <GuardedRoute element={<JoinTaskBoard />} auth={true} />
              }
            />
            <Route
              path="/invoices/:invoiceId"
              element={
                <GuardedRoute element={<InvoiceView />} auth={true} />
              }
            />
            <Route path="/join-board/:boardId" element={
              <GuardedRoute
                element={<JoinBoard />}
                auth={true}
              />
            } />
            <Route path="/join-workspace/:workspaceId" element={
              <GuardedRoute
                element={<JoinWorkSpace />}
                auth={true}
              />
            } />
            <Route path="*" element={<Navigate to="./login" replace />} />

            <Route
              path="/calendar-view"
              element={
                <GuardedRoute element={<CalendarView />} auth={true} />
              }
            />
            <Route
              path="/page-not-found"
              element={<PageNotFound />}
            />
            <Route
              path="/user-overview"
              element={
                <GuardedRoute element={<UserOverview />} auth={true} />
              }
            />
            <Route
              path="/session-timeline/:userId"
              element={
                <GuardedRoute 
                  element={<SessionTimeline />} 
                  auth={true}
                  role="admin"
                />
              }
            />
          </Routes>
          <ConditionalWrapper shouldLoadSelector={(state: CommonRootState) => state.PaymentFormReducer.paymentSuccess}>
            <PaymentThankYouPopup />
          </ConditionalWrapper>
        </HistoryRouter >






        {/* 
                    <ConditionalWrapper shouldLoadSelector={(state: CommonRootState) => state.PaymentFormReducer.open}>
            <PaymentForm />
          </ConditionalWrapper>
          
          
          <SubscriptionUpgradePopup />
          <ChargebeeUpdatePaymentMethodPopup /> */}
      </BTThemeProvider >
    );
  };

  return sitePages();
}
