import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Chip, Tooltip, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { DBSubscriptionThunks, subscriptionSelectByQuery } from '@common-reducers/DBServiceThunks';
import { SubscriptionObject } from '@common/models/subscription';
import moment from 'moment';
import { useEffect, useState, useMemo } from 'react';
import { SubscriptionStatusChip } from '../../components/subscription-status-chip';
import { selectCurrentWorkspace } from '@common-reducers/UserSelectors';
import { Workspace } from '@common/models/workspace';
import LaunchIcon from '@mui/icons-material/Launch';

interface SubscriptionsTabProps {
    supportedUserId: string;
}

export const SubscriptionsTab = ({ supportedUserId }: SubscriptionsTabProps) => {
    const dispatch = useAppDispatch();
    const allSubscriptions = useAppSelector(state => subscriptionSelectByQuery(state.DBSubscriptionReducer, {})) || [];
    const currentWorkspace = useAppSelector(selectCurrentWorkspace) as Workspace;
    const [isLoading, setIsLoading] = useState(true);

    // Filter subscriptions by owner only
    const subscriptions = useMemo(() => {
        return allSubscriptions.filter(subscription => subscription.owner === supportedUserId);
    }, [allSubscriptions, supportedUserId]);

    useEffect(() => {
        setIsLoading(true);
        dispatch(DBSubscriptionThunks.find({})).finally(() => {
            setIsLoading(false);
        });
    }, [dispatch]);

    const getBillingDuration = (subscription: any) => {
        if (subscription.type === 'chargebee') {
            const [, , interval] = (subscription.plan_id as string).split('-');
            return interval === 'Monthly' ? 'Monthly' : 'Annual';
        }

        if (subscription.plan?.intervalUnit === 'year') {
            return 'Annual';
        }
        if (subscription.plan?.intervalUnit === 'month') {
            return 'Monthly';
        }

        return subscription.plan?.intervalUnit || 'N/A';
    };

    const getPlanName = (subscription: any) => {
        if (subscription.type === 'chargebee') {
            const [planName] = (subscription.plan_id as string).split('-');
            return planName;
        }
        return subscription.plan?.planType || 'N/A';
    };

    const getSubscriptionUrl = (subscription: any) => {
        if (subscription.type === 'chargebee') {
            return `https://blueticks.chargebee.com/d/subscriptions/${subscription.subscription_id}`;
        }
        return null;
    };

    const SubscriptionLink = ({ subscription }: { subscription: any }) => {
        const url = getSubscriptionUrl(subscription);
        if (!url) {
            return <span>{subscription.subscription_id || subscription._id}</span>;
        }
        
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <span>{subscription.subscription_id || subscription._id}</span>
                <IconButton 
                    size="small" 
                    href={url} 
                    target="_blank"
                    sx={{ padding: '2px' }}
                >
                    <LaunchIcon sx={{ fontSize: '1rem' }} />
                </IconButton>
            </div>
        );
    };

    if (isLoading) {
        return <Typography>Loading subscriptions...</Typography>;
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Workspace</TableCell>
                        <TableCell>Plan</TableCell>
                        <TableCell>Billing Duration</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>Subscription ID</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {subscriptions.map((subscription) => {
                        const subscriptionObj = new SubscriptionObject(subscription);
                        const isCurrentWorkspace = subscription.workspaceId === currentWorkspace?._id;
                        return (
                            <TableRow key={subscription._id}>
                                <TableCell>
                                    <Tooltip title={`Workspace ID: ${subscription.workspaceId}, Current Workspace ID: ${currentWorkspace?._id}`}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            {subscription.workspace?.name || 'N/A'}
                                            {isCurrentWorkspace && (
                                                <Chip
                                                    label="Current"
                                                    size="small"
                                                    color="primary"
                                                    sx={{ 
                                                        height: '20px',
                                                        fontSize: '0.75rem'
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>{getPlanName(subscription)}</TableCell>
                                <TableCell>{getBillingDuration(subscription)}</TableCell>
                                <TableCell>{`$${subscriptionObj.amount}`}</TableCell>
                                <TableCell>
                                    <SubscriptionStatusChip 
                                        status={subscription.status} 
                                        subscription={subscription}
                                        showManagementLink={false}
                                    />
                                </TableCell>
                                <TableCell>{moment(subscription.start_time).format('YYYY-MM-DD')}</TableCell>
                                <TableCell>
                                    <Tooltip title={`Type: ${subscription.type}`}>
                                        <div>
                                            <SubscriptionLink subscription={subscription} />
                                        </div>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}; 