import { Chip, IconButton } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

interface SubscriptionStatusChipProps {
    status: string;
    subscription?: any;
    showManagementLink?: boolean;
}

export const SubscriptionStatusChip = ({ status, subscription, showManagementLink }: SubscriptionStatusChipProps) => {
    const getStatusColor = (status: string) => {
        switch (status?.toLowerCase()) {
            case 'active':
                return 'success';
            case 'cancelled':
            case 'non_renewing':
                return 'error';
            case 'in_trial':
                return 'info';
            case 'future':
                return 'warning';
            default:
                return 'default';
        }
    };

    const getStatusLabel = (status: string) => {
        switch (status?.toLowerCase()) {
            case 'non_renewing':
                return 'Non Renewing';
            case 'in_trial':
                return 'Trial';
            default:
                return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
        }
    };

    const getSubscriptionUrl = (subscription: any) => {
        if (subscription?.type === 'chargebee') {
            return `https://blueticks.chargebee.com/d/subscriptions/${subscription.subscription_id}`;
        }
        return null;
    };

    const subscriptionUrl = subscription && showManagementLink ? getSubscriptionUrl(subscription) : null;

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Chip
                label={getStatusLabel(status)}
                color={getStatusColor(status)}
                size="small"
                sx={{ 
                    fontWeight: 500,
                    minWidth: '90px'
                }}
            />
            {subscriptionUrl && (
                <IconButton 
                    size="small" 
                    href={subscriptionUrl} 
                    target="_blank"
                    sx={{ padding: '2px' }}
                >
                    <LaunchIcon sx={{ fontSize: '1rem' }} />
                </IconButton>
            )}
        </div>
    );
}; 