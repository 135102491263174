import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { SessionStatusPill } from '../../components/session-status-pill';
import { TimeAgo } from '../../components/time-ago';
import { CopyableText } from '../../components/copyable-text';

interface SessionsTabProps {
    supportedUserId: string;
}

const formatTimestamp = (timestamp: string | Date | undefined): string => {
    if (!timestamp) return '';
    if (timestamp instanceof Date) {
        return timestamp.toISOString();
    }
    return timestamp;
};

export function SessionsTab({ supportedUserId }: SessionsTabProps) {
    const sessions = useAppSelector((state) => 
        Object.values(state.DBSessionReducer.entities)
            .filter(session => session?.owner === supportedUserId)
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
    );

    const latestSession = sessions[0];

    return (
        <Box>
            {latestSession && (
                <Box sx={{ mb: 4, p: 3, bgcolor: 'background.paper', borderRadius: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                        <SessionStatusPill status={latestSession.status} />
                        <CopyableText text={latestSession._id} label="Session ID" />
                        <CopyableText text={latestSession.podId} label="Pod ID" />
                    </Box>
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 3 }}>
                        <Box>
                            <Box sx={{ color: 'text.secondary', fontSize: '0.75rem', mb: 0.5 }}>Last Seen</Box>
                            <TimeAgo timestamp={formatTimestamp(latestSession.lastSeen)} />
                        </Box>
                        <Box>
                            <Box sx={{ color: 'text.secondary', fontSize: '0.75rem', mb: 0.5 }}>Last Updated</Box>
                            <TimeAgo timestamp={formatTimestamp(latestSession.updatedAt)} />
                        </Box>
                        <Box>
                            <Box sx={{ color: 'text.secondary', fontSize: '0.75rem', mb: 0.5 }}>Created</Box>
                            <TimeAgo timestamp={formatTimestamp(latestSession.createdAt)} />
                        </Box>
                    </Box>
                </Box>
            )}

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>Session ID</TableCell>
                            <TableCell>WhatsApp ID</TableCell>
                            <TableCell>Pod ID</TableCell>
                            <TableCell>Failed Attempts</TableCell>
                            <TableCell>Last Seen</TableCell>
                            <TableCell>Last Updated</TableCell>
                            <TableCell>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sessions.map((session) => (
                            <TableRow key={session._id}>
                                <TableCell>
                                    <SessionStatusPill status={session.status} />
                                </TableCell>
                                <TableCell>
                                    <CopyableText text={session._id} />
                                </TableCell>
                                <TableCell>{session.myWId}</TableCell>
                                <TableCell>
                                    <CopyableText text={session.podId} />
                                </TableCell>
                                <TableCell>{session.failedAttempts}</TableCell>
                                <TableCell>
                                    <TimeAgo timestamp={formatTimestamp(session.lastSeen)} />
                                </TableCell>
                                <TableCell>
                                    <TimeAgo timestamp={formatTimestamp(session.updatedAt)} />
                                </TableCell>
                                <TableCell>
                                    <TimeAgo timestamp={formatTimestamp(session.createdAt)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
} 