import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { UserMessage } from '@common/models/user-message';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import ImageIcon from '@mui/icons-material/Image';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import mime from 'mime-types';

// Define Asset type inline since we can't import it
interface Asset {
    type?: string;
    name?: string;
    url?: string;
}

interface MediaPreviewProps {
    open: boolean;
    onClose: () => void;
    message: UserMessage | null;
}

interface MediaPreviewIconProps {
    assetData: Asset;
}

export function MediaPreviewIcon({ assetData }: MediaPreviewIconProps) {
    // Get mime type from the file type or deduce it from the filename
    const mimeType = assetData.type || 
        (assetData.name ? mime.lookup(assetData.name) || '' : '');
    
    const type = mimeType.toLowerCase();

    switch (true) {
        case type.startsWith('video/'):
            return <VideoFileIcon color="primary" />;
        case type.startsWith('image/'):
            return <ImageIcon color="primary" />;
        case type.startsWith('audio/') || type === 'application/ogg':
            return <AudioFileIcon color="primary" />;
        case type === 'application/pdf':
            return <PictureAsPdfIcon color="primary" />;
        default:
            return <AttachFileIcon color="primary" />;
    }
}

// Export as MediaPreview for backward compatibility
export const MediaPreview = MediaPreviewDialog;

export function MediaPreviewDialog({ open, onClose, message }: MediaPreviewProps) {
    if (!message || !message.asset) {
        return null;
    }

    const getMediaContent = () => {
        const asset = message.asset;
        if (!asset) return null;

        // Get mime type from the file type or deduce it from the filename
        const mimeType = asset.type || 
            (asset.name ? mime.lookup(asset.name) || '' : '');
        
        const type = mimeType.toLowerCase();

        switch (true) {
            case type.startsWith('video/'):
                return (
                    <video controls width="100%">
                        <source src={asset.url} type={type || 'video/mp4'} />
                        Your browser does not support the video tag.
                    </video>
                );
            case type.startsWith('image/'):
                return <img src={asset.url} alt="Preview" style={{ maxWidth: '100%' }} />;
            case type.startsWith('audio/') || type === 'application/ogg':
                return (
                    <audio controls style={{ width: '100%' }}>
                        <source src={asset.url} type={type || 'audio/mpeg'} />
                        Your browser does not support the audio element.
                    </audio>
                );
            case type === 'application/pdf':
                return (
                    <iframe
                        src={asset.url}
                        width="100%"
                        height="600px"
                        style={{ border: 'none' }}
                        title="PDF Preview"
                    />
                );
            default:
                return (
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                        <AttachFileIcon sx={{ fontSize: 48, mb: 2 }} />
                        <Typography>
                            This file type ({type || 'unknown'}) cannot be previewed.
                            <br />
                            <a href={asset.url} target="_blank" rel="noopener noreferrer">
                                Click here to download
                            </a>
                        </Typography>
                    </Box>
                );
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {message.asset?.name}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {getMediaContent()}
            </DialogContent>
        </Dialog>
    );
} 