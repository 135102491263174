import { Box, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAppSelector, useAppDispatch } from '@common-reducers/hooks/store.hook';
import { DBUserMessageThunks, userMessagesSelectByQuery } from '@common-reducers/DBServiceThunks';
import { UserMessage } from '@common/models/user-message';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { MessageCell } from './components/MessageCell';
import { MediaPreviewDialog } from './components/MediaPreview';
import { StatusCell } from './components/StatusCell';
import { ContactCell } from './components/ContactCell';
import { CampaignIdCell } from './components/CampaignIdCell';

interface MessagesTabProps {
    supportedUserId: string | null;
}

export function MessagesTab({ supportedUserId }: MessagesTabProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [selectedMedia, setSelectedMedia] = useState<UserMessage | null>(null);
    
    const dispatch = useAppDispatch();

    const messages = useAppSelector((state) => {
        return userMessagesSelectByQuery(state.DBUserMessageReducer, {});
    });

    const totalMessages = useAppSelector((state) => state.DBUserMessageReducer.total || 0);

    useEffect(() => {
        if (supportedUserId) {
            setIsLoading(true);
            const query = {
                owner: supportedUserId,
                $skip: paginationModel.page * paginationModel.pageSize,
                $limit: paginationModel.pageSize,
                $sort: { createdAt: -1 }
            };
            dispatch(DBUserMessageThunks.find(query))
                .finally(() => setIsLoading(false));
        }
    }, [supportedUserId, paginationModel.page, paginationModel.pageSize, dispatch]);

    const messageColumns: GridColDef[] = [
        { 
            field: 'message', 
            headerName: 'Message', 
            flex: 1,
            minWidth: 300,
            renderCell: (params) => (
                <MessageCell 
                    message={params.row.originalMessage}
                    onMediaClick={setSelectedMedia}
                />
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            renderCell: (params) => (
                <StatusCell 
                    status={params.row.status} 
                    dueDate={params.row.originalMessage.dueDate}
                />
            )
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 150,
            valueGetter: (params) => moment(params.row.createdAt).format('YYYY-MM-DD HH:mm:ss')
        },
        { 
            field: 'contact',
            headerName: 'Contact',
            width: 200,
            renderCell: (params) => (
                <ContactCell contact={params.row.originalMessage?.contactList?.[0]} />
            )
        },
        {
            field: 'campaignId',
            headerName: 'Campaign ID',
            width: 150,
            renderCell: (params) => (
                <CampaignIdCell campaignId={params.row.originalMessage?.campaignId} />
            )
        }
    ];

    const rows = messages.map((message) => ({
        id: message._id,
        originalMessage: message,
        message: message.message,
        status: message.status,
        createdAt: message.createdAt
    }));

    return (
        <>
            <Typography variant="h6" gutterBottom>Messages History</Typography>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={messageColumns}
                    loading={isLoading}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[10, 25, 50]}
                    rowCount={totalMessages}
                    paginationMode="server"
                    sx={{
                        '& .MuiDataGrid-cell': {
                            overflow: 'visible !important',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }
                    }}
                />
            </Box>

            <MediaPreviewDialog 
                open={!!selectedMedia} 
                onClose={() => setSelectedMedia(null)} 
                message={selectedMedia} 
            />
        </>
    );
} 