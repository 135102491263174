import { appConfig } from "@common-config/app.config";
import { UserMessage } from "@wa-communication-layer/wa-interfaces";
import { WhatsappId } from "@wa-communication-layer/whatsapp";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import Resizer from 'react-image-file-resizer';
import { isExtensionContextOrWhatsappView } from "./detect-context";

export function loadScript(src): Promise<Event> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.onload = resolve;
    script.onerror = () => reject(new Error(`Failed to load script ${src}`));
    document.head.appendChild(script);
  });
}

export function safeCreateObjectURL(blob: Blob): string {
  try {
    return URL.createObjectURL(blob);
  } catch (e) {
    return "";
  }
}

export function getContext(): string {
  let context = "unkown";
  if (chrome.runtime !== undefined && chrome.runtime.id) {
    if (typeof window === "undefined") {
      context = "background script";
    } else {
      context = "content script";
    }
  } else {
    context = "page";
  }
  return context;
}


export const useConstructor = (callBack = () => { }) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

export function modifyPlanName(planName) {

  // if planName is not string, return it as is
  if (typeof planName !== 'string') {
    return "";
  }

  let [firstPlanNameToken] = planName.split('-');

  const replacements = {
    'Scheduler': 'Basic',
    'Manager': 'Standard',
    'Campaigner': 'Pro'
  };

  // Replace the matching keys in the planName with their corresponding values in replacements
  for (const key in replacements) {
    if (firstPlanNameToken.includes(key)) {
      firstPlanNameToken = firstPlanNameToken.replace(new RegExp(`\\b${key}\\b`, 'gi'), replacements[key]);
    }
  }
  // Check if the planName string contains  ' 1 ' If true, replace 'seats' with 'seat' in the planName.
  if (planName.includes(' 1 ')) {
    planName = planName.replace('seats', 'seat');
  }

  // Return the modified planName
  return firstPlanNameToken;
}



export function escapeHtml(unsafe) {
  if (!unsafe) return "";

  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}


export function hexToRGB(hex: string, alpha: string) {

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
}

export function getDisplayNameFromFirstLastAndEmail(firstName?, lastName?, email?): string {

  let displayName = '';

  if (firstName) {
    displayName = firstName;
  }

  if (lastName) {
    displayName += ' ' + lastName;
  }

  if (displayName === '' && email) {
    displayName += email
  }

  return displayName.trim()

}

export const insertTextAtCursor = (
  ref: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
  text: string
) => {
  const primitivePlaceholderFormat = text.replace(/\s+/g, '').toLowerCase()
  const inputElement = ref?.current?.querySelector("textarea");

  let msg;
  let textBeforeCursorPosition;
  if (inputElement) {
    const cursorPosition = inputElement.selectionStart;
    textBeforeCursorPosition = inputElement?.value.substring(
      0,
      cursorPosition
    );
    const textAfterCursorPosition = inputElement?.value.substring(
      cursorPosition,
      inputElement.value.length
    );

    msg = `${textBeforeCursorPosition ?? ""}${primitivePlaceholderFormat}${textAfterCursorPosition ?? ""
      }`;




  }
  inputElement.value = msg

  const newCursorPosition = textBeforeCursorPosition.length + primitivePlaceholderFormat.length;

  inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
  inputElement.focus();

  inputElement?.focus();

  return inputElement ? inputElement.value : null;
};

export const messageFormatBtn = (
  ref: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
  wrap: "*" | "_" | "~"
) => {
  const inputElement: any = ref.current;

  if (inputElement && (inputElement instanceof HTMLTextAreaElement || inputElement instanceof HTMLInputElement)) {
    let res;
    if (inputElement.selectionStart !== inputElement.selectionEnd) {
      const msg = inputElement.value;

      res = [
        msg.slice(0, inputElement.selectionStart),
        wrap,
        msg.slice(inputElement.selectionStart, inputElement.selectionEnd),
        wrap,
        msg.slice(inputElement.selectionEnd),
      ].join("");

      // Set the new value to the input element
      inputElement.value = res;

      // Set the cursor position after the formatting
      const cursorPosition = inputElement.selectionStart + wrap.length;
      inputElement.setSelectionRange(cursorPosition, cursorPosition);
    }

    inputElement.focus();

    return res;
  } else if (inputElement.children[0] instanceof HTMLTextAreaElement) {
    let res;
    if (inputElement.children[0].selectionStart !== inputElement.children[0].selectionEnd) {
      const msg = inputElement.children[0].value;

      res = [
        msg.slice(0, inputElement.children[0].selectionStart),
        wrap,
        msg.slice(inputElement.children[0].selectionStart, inputElement.children[0].selectionEnd),
        wrap,
        msg.slice(inputElement.children[0].selectionEnd),
      ].join("");

      // Set the new value to the input element
      inputElement.children[0].value = res;

      // Set the cursor position after the formatting
      const cursorPosition = inputElement.children[0].selectionStart + wrap.length;
      inputElement.children[0].setSelectionRange(cursorPosition, cursorPosition);
    }

    inputElement.children[0].focus();

    return res;
  } else {
    console.error("Ref did not point to a textarea/input element.");
    return null;
  }
};


export async function fileToBase64(file: File | Blob): Promise<string> {
  let base64Output;
  try {
    const resizedImage = await new Promise<string>((resolve, reject) => {
      Resizer.imageFileResizer(file, 162, 162, 'JPEG', 100, 0, (image: string) => resolve(image), 'base64');
    })
    const [_, image] = resizedImage.split(',')
    base64Output = image;
  } catch (e) {
    console.warn('Unable to resize image')
  }
  return base64Output;
}


export function isValidEmail(email: string | undefined) {
  if (!email) return false;
  // Escape special characters in the email before using it in regex
  const regex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
  return regex.test(email);
}

export const useFocus = (): [MutableRefObject<any>, () => void] => {
  const htmlElRef = useRef(null)
  const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

  return [htmlElRef, setFocus]
}

export interface OpenWindowOptions {
  popup?: boolean;
  eventName?: string;
}

export function openWindow(url, title, options?: OpenWindowOptions): Promise<void> {
  const { popup, eventName } = options ?? {};
  return new Promise(async (resolve, reject) => {
    const ref = window.open(url, title, `target=_blank,${popup ? 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=1000,left=100,top=100' : ''}`)

    let result;
    const timer = setInterval(() => {
      if (ref.closed) {
        clearInterval(timer);
        resolve(result);
      }
    }, 500);

    if (eventName) {
      result = await new Promise(resolve => window.addEventListener(eventName, resolve));
      console.log(result);
    }
  });
}

export const varNameFromObject = (varObject: any) => {
  const [varName] = Object.keys(varObject);
  return varName;
}

export const openPaymentSite = async (accessToken: string) => {
  const url = `${appConfig.PRICING_PAGE}/account-subscription?v=1&accessToken=${accessToken}`;
  if (chrome.tabs) {
    // We are on the extension popup
    chrome.tabs.create({
      url
    });
  } else if (chrome.extension) {
    window.open(url, "_blank");

  } else {
    // We are on the website
    window.location.href = url;
  }
}

export const serializedIdToObject = (serializedId: string): WhatsappId => {
  const [user, server] = serializedId.split('@');
  return {
    user,
    server,
    _serialized: serializedId,
  }
}

export function getFromExtensionLocalStorage(key) {
  return new Promise((resolve, reject) => {
    chrome.storage.local.get(key, (result) => {
      if (chrome.runtime.lastError) {
        reject(chrome.runtime.lastError);
      } else {
        resolve(result[key]);
      }
    });
  });
}

export async function isLoggingEnabled() {

  let loggingEnabledByURL = false;
  let loggingEnabledByStoarge = false;

  if (isExtensionContextOrWhatsappView()) {
    loggingEnabledByStoarge = Boolean(await getFromExtensionLocalStorage('loggingEnabled'));
  }
  else {
    loggingEnabledByStoarge = Boolean(localStorage.getItem('loggingEnabled'));
    const params = new URLSearchParams(window.location.search);
    loggingEnabledByURL = params.get('loggingEnabled') === 'true';
  }

  return loggingEnabledByStoarge || loggingEnabledByURL;

}


// need to find the right place to call this function
export async function disableProdLogging() {

  //console.log(appConfig);
  //console.log("appConfig.ENV", appConfig.ENV);

  switch (appConfig.ENV) {
    case "production":
    case "prod":
    case "development":
    case "dev": {
      const loggingEnabled = await isLoggingEnabled();
      console.log("loggingEnabled", loggingEnabled);
      if (!loggingEnabled) {
        console.log = function () { };
      }
      break;
    }
  }



}



export async function blobToBase64Async(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result as string);
    };
  });
}

export async function uploadFilePut(file: File, url: string): Promise<void> {
  await axios({
    method: "PUT",
    url: url,
    data: file,
    headers: {
      "Content-Type": file.type,
    },
  });
}

export async function getBase64ImageFromUrl(imageUrl: string): Promise<string> {
  let res;
  try {
    const response: AxiosResponse<Blob> = await axios.get(
      imageUrl,
      { responseType: "blob" }
    );
    const blob = response.data;

    const base64data = await blobToBase64Async(blob);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, thumbnail] = base64data.split(',');
    res = thumbnail;
  } catch (e) {
    //
  }

  return res;
}

export function getUrls(str: string): Set<string> | undefined {

  const regexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,500}\.[a-zA-Z0-9()]{1,500}([-a-zA-Z0-9@:%_\+~#?!&\/\/\u00A0-\uFFFF]*[a-zA-Z0-9@:%_\+~#=\/\u00A0-\uFFFF])*(\.[a-zA-Z0-9]{1,500})?(\?[a-zA-Z0-9@:%_\+~#&=\/\u00A0-\uFFFF]*)?/gi;

  const emptySet = new Set<string>();
  const bracketsRegexp = /[()]/g;
  if (typeof str !== "string") {
    return emptySet;
  }

  if (str) {
    let urls = str.match(regexp);
    if (urls) {
      return new Set(urls.map((item) => item.replace(bracketsRegexp, "")));
    } else {
      return emptySet;
    }
  } else {
    return emptySet;
  }
}


export const extractTimeFromISOString = (isoString) => {
  const date = new Date(isoString);
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  return `${hours}:${minutes}`;
}



export const changeTimeInISOString = (isoString, newTime) => {
  // Validate the newTime format
  const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  if (!timePattern.test(newTime)) {
    throw new Error('Invalid time format. Please provide time in "hh:mm" format.');
  }

  // Parse the ISO string as local time
  const localDateTime = moment(isoString).format('YYYY-MM-DD') + 'T' + newTime;

  // Convert the local time to UTC
  const utcTime = moment(localDateTime).utc().format();

  return utcTime;
}


export const getCampaignDueTime = (scheduleDueDate?: string): moment.Moment => {
  const dueTime: moment.Moment = moment.utc(scheduleDueDate);
  const { hours, minutes } = moment(scheduleDueDate).startOf('minute').toObject();
  dueTime.hours(hours);
  dueTime.minutes(minutes);
  return dueTime;
};


export const isScheduleValid = (scheduleDueDate?: string) => {
  const inputTime = moment.utc(scheduleDueDate);
  const currentTime = moment.utc();
  return inputTime.isAfter(currentTime);
};

export const useCountUp = ({ isCounting, start, end, minDuration, maxDuration }) => {
  const [value, setValue] = useState(start);
  const requestRef = useRef(null);
  const isResetting = useRef(false); // Track if we are in the process of resetting

  const resetAndAnimate = useCallback(() => {
    const duration = minDuration + Math.random() * (maxDuration - minDuration); // Random duration within specified range
    let startTime;

    const animate = (time) => {
      if (!startTime) {
        startTime = time;
      }
      const timeElapsed = time - startTime;
      const progress = timeElapsed / duration;
      const currentValue = Math.min(start + (end - start) * progress, end);

      if (currentValue >= end && !isResetting.current) {
        isResetting.current = true;
        setValue(start); // Instantly reset to start value
        requestAnimationFrame(() => {
          isResetting.current = false;
          resetAndAnimate(); // Start again
        });
      } else if (!isResetting.current) {
        setValue(currentValue);
        requestRef.current = requestAnimationFrame(animate);
      }
    };

    requestRef.current = requestAnimationFrame(animate);
  }, [start, end, minDuration, maxDuration]);

  useEffect(() => {
    if (isCounting) {
      resetAndAnimate();
    }

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [isCounting, resetAndAnimate]);

  return value;
};

export const getMessageFromInputField = () => {
  const message = Array.from(document.querySelector("footer [contenteditable]")?.querySelectorAll('p'))
    .map(paragraph => paragraph.textContent).join("\r\n");
  return message;
}



export const generateNewAudienceName = () => {
  // ${t('importExcelToBlueticks.newAudience')}
  return `New Audience ${moment().format('YYYY-MM-DD HH:mm')}`;
}


export const getHandleId = (handles, type: 'target' | 'source', index) => {
  if (type === 'target') {
    return handles?.inputs[index]?.id;
  } else {
    return handles?.outputs[index]?.id;
  }
}

export const getChatbotTempId = () => {
  return 'tempBotElementId' + Math.floor(Math.random() * 1000000000000000000000000)
}

export const handleMentionsFormatInMessage = (message: string) => {
  if (!message) {
    return '';
  }
  // Regex pattern to match @[displayName](contactId)
  const pattern = /@\[(.*?)\]\(.*?\)/g;

  // Replace the matches with @displayName
  const result = message?.replace(pattern, '@$1');

  return result;
};


export const MessageOvedueLimit = 30;

export const isMessageIsWithinSendingLimits = (message: UserMessage) => {
  const lowerLimit = moment.utc().subtract(MessageOvedueLimit, "minutes");
  const upperLimit = moment.utc();
  const messageDueDate = moment.utc(message.dueDate);
  const isWithinLimits = message.dueDate && messageDueDate.isBetween(lowerLimit, upperLimit);
  return isWithinLimits

}

export const isMessageOverDue = (message: UserMessage) => {
  const messageDueDate = moment.utc(message.dueDate);
  const isMessageInThePast = messageDueDate.isBefore(moment.utc());
  return isMessageInThePast && !isMessageIsWithinSendingLimits(message);
}
