import RowStack from '@common-components/common/row-stack/RowStack'
import SocketConnectionStatusButton from '@common-components/socket-connection-status-button/socket-connection-status-button'
import { accessFormPopupActions } from '@common-reducers/AccessFormPopupReducer'
import { selectIsWaSocketsLimitReached } from '@common-reducers/UserSelectors'
import { BulkView, setActiveBulkView, setActiveTab, toggleBulkDashboardDisplay } from '@common-reducers/CampaignReducer'
import { mainSliceActions, setStartChatWithPhoneNumberOpen } from '@common-reducers/MainReducer'
import { taskActions } from '@common-reducers/TasksReducer'
import { selectUser } from '@common-reducers/UserSelectors'
import { openSocketModalThunk } from '@common-reducers/UserThunks'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { selectUserId } from '@common/reducers/UserSelectors'
import { checkWaSocketsLimitThunk } from "@extension-context-content/thunks/WhatsappThunks"
import { Campaign, Chat, Settings } from '@mui/icons-material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { IconButton, Menu, Tooltip } from '@mui/material'
import { Stack } from '@mui/system'
import { useEffect, useState } from 'react'
import CampaignExtensionMenu from './campaign-extension-menu'
import ManageExtensionMenu from './manage-extension-menu'
import ManageMessagesMenu from './manage-messages-menu'
import MenuIconButton from './menu-icon-button'

// const blueStopColors = ['#6CD1F1', '#269BE9']; // Original blue gradient stops
export const greenStopColors = ['#76C8A1', '#34A853']; // Green gradient stops
export const redStopColors = ['#FA8072', '#E9403A']; // Red gradient stops

export default function ExtensionTopButtonBar() {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [modelType, setModalType] = useState<string>('')
    const [open, setOpen] = useState<boolean>(false)
    const user = useAppSelector(selectUser);
    const isWaSocketsLimitReached = useAppSelector(state => selectIsWaSocketsLimitReached(state))

    useEffect(() => {
        dispatch(checkWaSocketsLimitThunk());
    }, []);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>, menuType: string) => {
        if (!user) {
            dispatch(accessFormPopupActions.setStatus('Signup'))
            return;
        }

        setAnchorEl(event.currentTarget)
        setModalType(menuType)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setAnchorEl(null);
        setModalType('')
    };

    const toggleBulkPanel = (campaignView?: BulkView) => {
        dispatch(taskActions.setIsTasksPanelOpen(false));
        if (campaignView) {
            dispatch(setActiveBulkView(campaignView))
            if (campaignView === 'newCampaignView') {
                dispatch(setActiveTab('audiences'))
            }
        }
        dispatch(toggleBulkDashboardDisplay());
        handleClose()
    };

    const manageCampaignEntities = () => {
        dispatch(taskActions.setIsTasksPanelOpen(false));
        dispatch(setActiveTab("manageAudiences"))
        dispatch(setActiveBulkView('campaignManagementView'))
        dispatch(toggleBulkDashboardDisplay());
        handleClose()
    }

    return (
        <Stack direction='row' sx={{
            gap: '10px',
            height: '47px',
            zIndex: 1, // for dev purposes. remove once look&feel is complete`  
            p: 0,
            alignItems: 'center',
            justifyContent: 'space-between',
            width: "480px",
        }}>
            <RowStack spacing={2}>
                <BlueticksIconButton onClick={(e) => handleButtonClick(e, 'manageExtensionMessagingMenu')} />
                <MenuIconButton
                    onClick={(e) => dispatch(mainSliceActions.toggleScheduledContactList())}
                    Icon={<Chat fontSize='small' />}
                    alt="Open Messages"
                />
                <MenuIconButton
                    onClick={(e) => toggleBulkPanel('newCampaignView')}
                    Icon={<Campaign />}
                    alt="Launch New Campaign"
                />
                <MenuIconButton
                    onClick={(e) => dispatch(setStartChatWithPhoneNumberOpen(true))}
                    Icon={<PersonAddIcon />}
                    alt="Open Chat"
                />

                {!isWaSocketsLimitReached &&
                    <SocketConnectionStatusButton onClick={(e) => dispatch(openSocketModalThunk())} />
                }

                <MenuIconButton
                    onClick={manageCampaignEntities}
                    Icon={<Settings />}
                    alt="Manage campaigns"
                />

                {/* <MenuIconButton
                    onClick={(e) => dispatch(setSetNewStatusPopupOpen(true))}
                    Icon={<MapsUgc />}
                    alt="Set status"
                /> */}
            </RowStack>


            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        display: (open && modelType !== 'phoneChat') ? 'block' : 'none',
                    },
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        padding: 0,
                    },
                }}>
                <div>
                    {modelType === 'manageExtensionMessagingMenu' &&
                        <ManageExtensionMenu handleClose={handleClose} />
                    }
                    {modelType === 'openMessagesMenu' &&
                        <ManageMessagesMenu handleClose={handleClose} />
                    }
                    {modelType === 'openCampaignMenu' &&
                        <CampaignExtensionMenu handleClose={handleClose} />
                    }
                </div>
            </Menu>
        </Stack>
    )
}

const BlueticksIconButton = ({ onClick }) => {
    const userId = useAppSelector(selectUserId);
    return (
        <Tooltip title={!userId ? 'Login' : "Account Options"}>
            <IconButton onClick={onClick} sx={{
                background: 'linear-gradient(-135deg, #42c3ec, #016eb8)', // Apply gradient here
                color: 'white',
                width: 38, // Adjusting size
                height: 38,
                minHeight: 'auto', // Override minimum height
                '.MuiSvgIcon-root': { // Target the icon inside the Fab
                    fontSize: '25px', // Icon size
                },
                boxShadow: 'none',
                border: "none",
                transition: 'background 0.3s ease',
                ':hover': { // Change background on hover
                    background: 'linear-gradient(-135deg, #6dd1f1, #259ae9)', // Update these values for your hover gradient
                }
            }}>
                <svg fill='white' width="30" height="30" viewBox={"0 0 24 24"}>
                    <g>
                        <path d={'M17.3 6.3a.9959.9959 0 0 0-1.41 0l-5.64 5.64 1.41 1.41L17.3 7.7c.38-.38.38-1.02 0-1.4zm4.24-.01-9.88 9.88-3.48-3.47a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L22.95 7.71c.39-.39.39-1.02 0-1.41h-.01c-.38-.4-1.01-.4-1.4-.01zM1.12 14.12 5.3 18.3c.39.39 1.02.39 1.41 0l.7-.7-4.88-4.9a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.03 0 1.42z'} />
                    </g>
                </svg>
            </IconButton>
        </Tooltip>
    )
}
