import { Currency, CurrencyList } from "@common-components/payment-form/currency-codes";
import { BluesnapShopper } from "@common-models/bluesnap-shopper";
import { selectAmountDue, staticSelectBillingInfo } from '@common-reducers/PaymentFormSelectors';
import { activeSubscriptionSelector } from '@common-reducers/UserSelectors';
import { createAsyncThunk } from "@reduxjs/toolkit";

import { namespace } from '@common/config/constants';

import { CommonRootState } from "@common/types/common-root-state-type";
import * as postalCodes from 'postal-codes-js';
import { backendApiEndpoints, GetCheckoutPageUrlParams, GetCheckoutPageUrlResponse } from "./backend-api/backend-api";
import { DBSubscriptionThunks } from "@common-reducers/DBServiceThunks";
import { paymentFormActions } from './PaymentFormReducer';
import { useTranslation } from "react-i18next";
import { selectCurrentUser } from "./UserSelectors";

export interface openPaymentDialogThunkParams {
    planId?: string;
    contractId?: string;
    workspaceId?: string;
    seats?: number;
    paymentSuccess?: boolean;
}

export const openPaymentDialogThunk = createAsyncThunk<void, openPaymentDialogThunkParams, { state: CommonRootState }>("openPaymentDialogThunk", async (params, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    const state = thunkApi.getState();

    const user = selectCurrentUser(state);

    document.title = 'Payment Form';

    dispatch(paymentFormActions.openDialog({
        ...params,
        user,
    }));

    const productId = params.planId;
    if (productId) {
        dispatch(calculateCartThunk())
    }
});


export const fetchPaymentTokenThunk = createAsyncThunk<void, void, { state: CommonRootState }>("fetchPaymentTokenThunk", async (_, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    // dispatch(paymentFormActions.setCCToken(undefined));

    try {
        const result = await dispatch(backendApiEndpoints.getPaymentFieldToken.initiate()).unwrap();
        const { token, endpoint } = result;
        console.log(`Refreshed CC Token. token: ${token}, endpoint: ${endpoint}`);
        // dispatch(paymentFormActions.setCCToken(token));
        dispatch(paymentFormActions.setEndpoint(endpoint));
    } catch (error) {
        console.error("Error fetching payment token:", error);
    }
});

export const closePaymentDialogThunk = createAsyncThunk<void, void, { state: CommonRootState }>("closePaymentDialogThunk", async (_, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    const { t } = useTranslation(namespace);

    document.title = t(`${namespace}:accountSubscriptionView.buySubscription`);

    dispatch(paymentFormActions.setPaymentSuccessType());
    dispatch(paymentFormActions.setPaymentSuccess(false))
    dispatch(paymentFormActions.closeDialog())
});

export const calculateCartThunk = createAsyncThunk<void, void, { state: CommonRootState }>("calculateCartThunk", async (_, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    let state = thunkApi.getState();
    const { planId, appliedCouponCode, seats } = state.PaymentFormReducer;

    console.log('Calculating cart');
    dispatch(paymentFormActions.setCalculatingCart(true));

    const estimate: Estimate = await dispatch(backendApiEndpoints.createEstimate.initiate({
        quantity: seats ?? 1,
        item_price_id: planId,
        coupon_ids: appliedCouponCode ? [appliedCouponCode] : [],
    })).unwrap();

    console.log(estimate);

    if (estimate) {
        dispatch(paymentFormActions.setEstimate(estimate));
    } else {
        dispatch(paymentFormActions.setCartErrorMessage('Error calculating cart'));
    }

    console.log('Done calculating cart');
    dispatch(paymentFormActions.setCalculatingCart(false));
});

export const createPaymentIntentThunk = createAsyncThunk<void, void, { state: CommonRootState }>("createPaymentIntentThunk", async (_, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    let state = thunkApi.getState();
    const { seats } = state.PaymentFormReducer;
    const amountDue = selectAmountDue(state);
    const paymentMethod = state.PaymentFormReducer.paymentMethod ?? 'CreditCard';

    const payment_method_type = paymentMethod === 'CreditCard' ? 'card' : 'paypal_express_checkout';

    const paymentIntent = await dispatch(backendApiEndpoints.createIntent.initiate({
        amount: amountDue,
        payment_method_type,
        quantity: seats,
    })).unwrap();
    dispatch(paymentFormActions.setPaymentIntent(paymentIntent));
});

export const setAppliedCouponCodeThunk = createAsyncThunk<void, string, { state: CommonRootState }>("setAppliedCouponCodeThunk", async (couponCode, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(paymentFormActions.setAppliedCouponCode(couponCode));
    dispatch(calculateCartThunk());
});

export const setCurrencyThunk = createAsyncThunk<void, string, { state: CommonRootState }>("setCurrencyThunk", async (currencyCode, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    const currency: Currency = CurrencyList.find(currency => currency.code === currencyCode);

    dispatch(paymentFormActions.setCurrency(currency));
    dispatch(calculateCartThunk());
});


export const validateVATNumberThunk = createAsyncThunk<void, string | undefined, { state: CommonRootState }>("validateVATNumberThunk", async (vatIdInput, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    const state = thunkApi.getState();
    const { country } = state.PaymentFormReducer;
    const { vatId } = state.PaymentFormReducer;

    const chargebee = Chargebee.getInstance();

    const result = await chargebee.vat.validateVat({
        country: country.code,
        vat_number: vatIdInput ?? vatId
    });
    dispatch(paymentFormActions.setVatValidation({
        vatStatus: result.status,
        message: result.message
    }));
});



export const validateZipCodeThunk = createAsyncThunk(
    'paymentForm/validateZipCode',
    async (_, { getState }) => {
        const state = getState() as CommonRootState;
        const { zip, country } = state.PaymentFormReducer;

        if (!zip || !country?.code) {
            return { isValid: false, message: 'Please enter a zip code and select a country.' };
        }

        const validationResult = postalCodes.validate(country.code, zip);

        if (validationResult === true) {
            return { isValid: true, message: '' };
        } else if (typeof validationResult === 'string') {
            return { isValid: false, message: validationResult };
        } else {
            // Handle the case where validationResult is false
            return { isValid: false, message: `Invalid postal code for ${country.code}` };
        }
    }
);

// Thunk to fetch shopper/customer information
export const getShopperInfoThunk = createAsyncThunk(
    'paymentForm/getShopperInfo',
    async (_, thunkApi) => {
        const dispatch = thunkApi.dispatch;
        const state = thunkApi.getState() as CommonRootState;
        const workspaceSubscription = activeSubscriptionSelector(state);
        const data = await thunkApi.dispatch(backendApiEndpoints.getShopperInfo.initiate()).unwrap();

        let shopper: BluesnapShopper;
        let customer: Customer;
        const { paymentSourceList } = data;
        if (workspaceSubscription?.type === 'bluesnap') {
            shopper = data.shopper;
        } else {
            customer = data.customer;
        }
        const user = selectCurrentUser(state);

        const {
            firstName,
            lastName,
            address1,
            address2,
            zip,
            countryObject,
            companyName,
            vatId,
        } = staticSelectBillingInfo(user, workspaceSubscription, shopper, customer, paymentSourceList);

        return {
            data,
            type: workspaceSubscription?.type,
            firstName,
            lastName,
            address1,
            address2,
            zip,
            countryObject,
            companyName,
            vatId,
        };
    }
);

// Get checkout page url thunk
export const openCheckoutPageThunk = createAsyncThunk<void, GetCheckoutPageUrlParams>(
    'paymentForm/openCheckoutPageThunk',
    async (params, thunkApi) => {
        const dispatch = thunkApi.dispatch;
        dispatch(paymentFormActions.setIsGettingPaymentUrl(true));
        const { checkoutPageUrl } = await thunkApi.dispatch(backendApiEndpoints.getCheckoutPageUrl.initiate(params)).unwrap();
        window.location.href = checkoutPageUrl;
    }
);

// Get checkout page url without redirect
export const getCheckoutPageUrlThunk = createAsyncThunk<GetCheckoutPageUrlResponse, GetCheckoutPageUrlParams>(
    'paymentForm/getCheckoutPageUrlThunk',
    async (params, thunkApi) => {
        const dispatch = thunkApi.dispatch;
        dispatch(paymentFormActions.setIsGettingPaymentUrl(true));
        const response = await thunkApi.dispatch(backendApiEndpoints.getCheckoutPageUrl.initiate(params)).unwrap();
        return {
            checkoutPageUrl: response.checkoutPageUrl,
            debug: response.debug || {
                function: 'checkout_new_for_items',
                input: params
            }
        };
    }
);

export const getPortalSessionUrlThunk = createAsyncThunk<string, void, { state: CommonRootState }>(
    'paymentForm/getPortalSessionUrlThunk',
    async (_, thunkApi) => {
        const { portal_session_url } = await thunkApi.dispatch(backendApiEndpoints.getPortalSessionUrl.initiate()).unwrap();
        return portal_session_url;
    }
);


// Cancel active subscription thunk
export const cancelSubscriptionThunk = createAsyncThunk<void, void, { state: CommonRootState }>(
    'paymentForm/cancelSubscriptionThunk',
    async (_, thunkApi) => {
        await thunkApi.dispatch(backendApiEndpoints.cancelActiveSubscription.initiate());

        // Refresh the user's subscription status
        await thunkApi.dispatch(DBSubscriptionThunks.find({}));
    }
);