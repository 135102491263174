import { fetchRule } from "@common-components/schedule-picker/recurrence-utils";
import { ContactInfo } from "@common-models/contact-info";
import { Person } from "@common-models/person";
import { UserMessage } from "@common-models/user-message";
import { WASession } from "@common-models/wasession";
import { DBBoardsThunks, DBUserMessageThunks, DBUserThunks, webhooksSelectByQuery } from "@common-reducers/DBServiceThunks";
import { AnalyticsService } from "@common-services/analytics-service";
import { isExtensionContext } from "@common-services/detect-context";
import { postMessageWithResponse } from "@common-services/post-message-to-whatsapp-functions";
import { addScheduledMessageButtonActions } from "@common/reducers/AddScheduledMessageButtonReducer";
import { boardsActions } from "@common/reducers/BoardsReducer";
import { getBoardByChat } from "@common/reducers/BoardsSelectors";
import { notifierSubscribeThunk } from "@common/reducers/NotifierThunks";
import { getProfilePicThunk } from "@common/reducers/ProfilePicReducer";
import { updateBoardPersonListThunk } from "@common/reducers/TaskThunks";
import { selectCurrentUserSession, selectUser, selectUserId } from "@common/reducers/UserSelectors";
import { privateSetCurrentChat, setImportedContactsArray, setProfilePic, setStashedChat, setWAchats, setWAcontacts } from "@common/reducers/WhatsAppReducer";
import { selectProfilePic } from "@common/reducers/WhatsAppSelectors";
import { btWaCommRequest, createEntity, isWaSocketsLimitReached } from "@common/reducers/backend-api/backend-api";
import { CommonRootState } from "@common/types/common-root-state-type";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Chat, GetProfilePicResponse, WhatsappChatMessage, WhatsappContact } from "@wa-communication-layer/whatsapp";
import moment from "moment";
import { RRule } from "rrule";







import { blobMap } from '@common-services/blob-service'; // Adjust import for blobMap if needed
import { accessFormPopupActions } from "@common/reducers/AccessFormPopupReducer";
import { MessageState } from "@common/types/interface";
import { ContentMessageServiceActions } from "@extension-context-content/services/contentMessageServiceActions";
import { ExtensionRootState } from "@extension/context/common/store/extension-store";
import randomstring from 'randomstring';


export class Deferred<T = any> {
    promise: Promise<T>;
    reject!: () => void;
    resolve!: (data: any) => void;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}


const analyticsService = new AnalyticsService();



// if (process.env.NODE_ENV === "development" && window.location.hostname.includes("localhost")) {
//     new MockWapi();
// }


export const handleNewMessageThunk = createAsyncThunk<
    void,
    WhatsappChatMessage,
    {}
>("handleNewMessageThunk", async (message, thunkApi) => {
    const { getState } = thunkApi;
    const dispatch = thunkApi.dispatch;
    const state = getState() as CommonRootState;

    const webhookList = webhooksSelectByQuery(state.DBWebhooksReducer, {})

    if (webhookList?.length > 0) {
        dispatch(createEntity.initiate({
            service: 'actions',
            entity: {
                cmd: "sendMessageToWebhook",
                message,
            }
        }))
        analyticsService.event("message_api_receive", {
            category: "messages",
            action: "api-receive",
            label: "regular",
        });
    }

    const userMessageList = Object.keys(
        state.DBUserMessageReducer.entities
    ).map((id) => state.DBUserMessageReducer.entities[id]);
    const contactMessageList = userMessageList.filter(_message => (_message.contactList ?? []).find(
        (contact) => contact.id === message.from?.id?._serialized
    ));
    if (contactMessageList) {
        for (const scheduledMessage of contactMessageList) {
            if (scheduledMessage.cancelIfReceived) {
                dispatch<any>(DBUserMessageThunks.delete({
                    entity: scheduledMessage,
                    options: { inCaseOfErrorRollbackOptimisticUpdateDisabled: true },
                }));
            }
        }
    }
});

export const subscribeCurrentBoardToSocket = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("subscribeCurrentBoardToSocket", async (_, thunkApi) => {

    const { dispatch, getState } = thunkApi;
    const state = getState();

    const chatBoard = getBoardByChat(state, state.WhatsAppReducer?.currentChat);
    if (chatBoard) {
        dispatch(notifierSubscribeThunk({ entityType: 'boards', entityId: chatBoard._id }))
    }
});

export const setCurrentChatThunk = createAsyncThunk<
    void,
    { chat: Chat }
>("setCurrentChatThunk", async ({ chat }, thunkApi) => {

    const { dispatch, getState } = thunkApi;
    const state: ExtensionRootState = getState() as ExtensionRootState;


    dispatch(privateSetCurrentChat(chat))

    const chatBoard = getBoardByChat(state, chat);
    if (chatBoard) {
        dispatch(notifierSubscribeThunk({ entityType: 'boards', entityId: chatBoard._id }))
    }
});

export const checkWaSocketsLimitThunk = createAsyncThunk(
    'checkWaSocketsLimit',
    async (_, thunkApi) => {
        const { dispatch, getState } = thunkApi;
        const state = getState() as ExtensionRootState;
        const userId = selectUserId(state)

        if (state?.AddScheduledMessageButtonReducer?.isWaSocketsLimitReached !== null) {
            return state?.AddScheduledMessageButtonReducer?.isWaSocketsLimitReached;
        }

        if (userId) {
            let res = await dispatch(isWaSocketsLimitReached.initiate({})).unwrap();
            dispatch(addScheduledMessageButtonActions.setIsSocketsLimitReached(res));
        }
    }
);



export const calculateNextDuedate = (dueDate: moment.Moment, message: UserMessage) => {
    if (!!message.rruleset) {
        const rule: RRule = fetchRule(message.rruleset);

        // Create a new Date object for the current time in UTC
        let currentDateUTC = moment.utc();

        // Get max of current date in UTC and due date in UTC
        let maxDateUTC = dueDate.isAfter(currentDateUTC) ? dueDate : currentDateUTC;

        let nextUTC = rule.after(maxDateUTC.toDate());
        if (nextUTC) {
            // Convert UTC date back to moment object
            dueDate = moment.utc(nextUTC);
        } else {
            return null;
        }
    } else {
        const diffUnits = moment.utc().diff(dueDate, message.recurenceUnit, true);

        if (diffUnits > 0) {
            const periods = Math.floor(diffUnits / message.recurrenceQuantity);
            dueDate.add((periods + 1) * message.recurrenceQuantity, message.recurenceUnit);
        }
    }

    return dueDate;
}




export const createWAContactBoardThunk = createAsyncThunk<
    void,
    { clearInput?: boolean },
    { state: CommonRootState }
>("getCurrentChatThunk", async (options, thunkApi) => {
    const state: CommonRootState = thunkApi.getState();
    const dispatch = thunkApi.dispatch;
    const currentChat: Chat = state.WhatsAppReducer.currentChat
    const userWA: WhatsappContact = state.WhatsAppReducer.me

    const user = selectUser(state)

    function createParticipant(participant: Partial<WhatsappContact>) {
        return {
            waId: participant?.id?._serialized ?? "",
            name: participant?.displayName ?? "",
            isMyContact: participant?.isMyContact,
            isWAContact: participant?.isWAContact,
        };
    }

    let personList = [];
    let _IdByWaChatIds: string[]

    if (currentChat?.isGroup) {
        personList = (currentChat?.participants ?? []).map(createParticipant);
        _IdByWaChatIds = [currentChat?.id?._serialized]
    } else {
        personList.push(createParticipant(currentChat.contact));
        personList.push(createParticipant(userWA));
        _IdByWaChatIds = [currentChat?.id?._serialized, userWA?.id?._serialized]
    }

    const board = {
        name: currentChat?.formattedTitle ?? currentChat?.displayName ?? currentChat?.name ?? currentChat.id?.user ?? 'New WA board',
        owner: user._id,
        personList: personList,
        isGroup: currentChat?.isGroup ?? false,
        isWABoard: true,
        _IdByWaChatIds: _IdByWaChatIds,
    };

    dispatch(boardsActions.setNewWaBoardStatus("creating WA board"));
    const waBoard: any = await dispatch(DBBoardsThunks.create(board));

    const personProfilePicPromiseList = currentChat.participants.map(participant =>
        dispatch(getProfilePicThunk(participant.id?._serialized)).unwrap()
    )

    Promise.all(personProfilePicPromiseList).then((responseList) => {
        const personList: Person[] = responseList.filter((response: any) => !response.error)
            .map((response: GetProfilePicResponse) => {
                const participant: Partial<WhatsappContact> | undefined = currentChat.participants.find((participant) => participant?.id?._serialized === response.waId)
                return {
                    ...participant,
                    profilePic: response.profilePic,
                }
            })

        dispatch(updateBoardPersonListThunk({
            boardId: waBoard.payload?._id,
            personList
        }));
    })
});


export const handleContactSelectionChangedThunk = createAsyncThunk<
    void,
    { contact: ContactInfo; isSelected: boolean },
    { state: CommonRootState }
>("scheduleMessage", async ({ contact, isSelected }, thunkApi) => {
    const state: CommonRootState = thunkApi.getState();
    const dispatch = thunkApi.dispatch;
    const importedContactsArray = state.WhatsAppReducer.importedContactsArray;

    const _importedContactsArray = [...importedContactsArray];
    const chatIndex = _importedContactsArray.findIndex(_contact => _contact.whatsappId === contact.whatsappId);
    if (chatIndex > -1) {
        _importedContactsArray.splice(chatIndex, 1);
    }
    if (isSelected) {
        _importedContactsArray.push(contact);
    }
    dispatch(setImportedContactsArray(_importedContactsArray))
})

export const toggleContactSelectionThunk = createAsyncThunk<
    void,
    { contact: ContactInfo },
    { state: CommonRootState }
>("toggleContactSelectionThunk", async ({ contact }, thunkApi) => {
    const state: CommonRootState = thunkApi.getState();
    const dispatch = thunkApi.dispatch;
    const isContactChecked = state.WhatsAppReducer.importedContactsArray.find(_contact => _contact.whatsappId === contact.id._serialized) !== undefined;
    dispatch(handleContactSelectionChangedThunk({ contact, isSelected: !isContactChecked }))
});



export const getProfilePicUrlThunk = createAsyncThunk<
    void,
    string,
    { state: CommonRootState }
>("getProfilePicUrlThunk", async (contactId, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    const state = thunkApi.getState();
    const session: WASession = selectCurrentUserSession(state)

    let profilePic = selectProfilePic(state, contactId);

    if (profilePic === undefined) {

        if (isExtensionContext()) {
            profilePic = await ContentMessageServiceActions.getProfilePicUrl(contactId);
            dispatch(setProfilePic({ contactId, profilePic: profilePic ?? '' }));
        }

        else if (session?.active) {
            dispatch(btWaCommRequest.initiate({
                type: 'requestMissingContactsImages',
                waId: [contactId]
            }))
        }

    }
});


export const getWaVersionThunk = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("getWaVersionThunk", async (_, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    const state = thunkApi.getState();
    const user = selectUser(state)

    const version: string = await postMessageWithResponse({ cmd: "getWaVersion" });

    if (version) {
        if (!user?.waVersion || user.waVersion !== version) {
            dispatch(DBUserThunks.patch({
                entity: {
                    _id: user._id,
                    waVersion: version
                }
            }))
        }
    }
});




export const requestMissingContactsImagesThunk = createAsyncThunk<
    void,
    any[],
    { state: CommonRootState }
>(
    "campaigns/getBoardEntitiesForImportModal",
    async (waContactsWithoutImageIdArray, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const state: CommonRootState = thunkAPI.getState();
        const session: WASession = selectCurrentUserSession(state)

        // const notifierSocket = io(appConfig.NOTIFIER_ENDPOINT, {
        //     transports: ["websocket"],
        //     upgrade: false,
        //     autoConnect: false,
        //   });

        //   notifierSocket.emit("get-profile-pics-by-wid-array", {
        //     type: 'requestMissingContactsImages',
        //     waId: [waContactsWithoutImageIdArray]
        //   });
        if (session?.active) {
            dispatch(btWaCommRequest.initiate({
                type: 'requestMissingContactsImages',
                waId: waContactsWithoutImageIdArray
            }))
        }

    })



//     export const updateRedisContactsProfilePicsMapAndArrayThunk = createAsyncThunk<
//     void,
//     any,
//     { state: CommonRootState }
// >(
//     "campaigns/getBoardEntitiesForImportModal",
//     async (waProfilePicsRecord, thunkAPI) => {
//         const state: CommonRootState = thunkAPI.getState();
//         const dispatch = thunkAPI.dispatch as AppThunkDispatch;

//         const redisContactsMap = state?.WhatsAppReducer?.redisContactsMap;

//         /// update redis contacts map with profile pics from waProfilePicsRecord
//         const updatedRedisContactsMap = { ...redisContactsMap }
//         for (const waId in waProfilePicsRecord) {
//             updatedRedisContactsMap[waId] = {
//                 ...updatedRedisContactsMap[waId],
//                 imgUrl: waProfilePicsRecord[waId]
//             }
//         }

//         dispatch(setRedisContactsMapAndArrayFromNotifierOrRest(updatedRedisContactsMap));

//     })

/*
export const toggleSelectAllContactsThunk = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("selectAllContactsThunk", async (isSelected, thunkApi) => {
    const state: CommonRootState = thunkApi.getState();
    const dispatch = thunkApi.dispatch;

    const importedContactList = state.WhatsAppReducer.importedContactsArray;

    if (importedContactList.length > 0) {
        dispatch(setImportedContactsArray([]))
    } else {
        const mergedContactList = selectMergedContactList(state);
        const audienceSelectedContactList = selectAudienceSelectedContactList(state);
        const audienceSelectedContactMap = audienceSelectedContactList.reduce((map, contactId) => {
            map[contactId] = contactId;
            return map;
        }, {} as Record<string, string>);

        const _importedContactsArray = mergedContactList.filter(contact => {
            return audienceSelectedContactMap[contact.id._serialized] === undefined;
        });
        dispatch(setImportedContactsArray(_importedContactsArray))
    }
});
*/


export const openOrCloseCalendarFromExtensionThunk = createAsyncThunk<
    void,
    boolean,
    { state: CommonRootState }
>("selectAllContactsThunk", async (open, thunkApi) => {
    const { getState, dispatch } = thunkApi
    const state = getState()

    if (open) {
        const currentChat = state.WhatsAppReducer.currentChat;
        dispatch(setStashedChat(currentChat));
        dispatch(privateSetCurrentChat(null));
    } else {
        const stashedChat = state.WhatsAppReducer.stashedChat;
        if (stashedChat) {
            dispatch(privateSetCurrentChat(stashedChat));
            dispatch(setStashedChat(null));
        }
    }
});



export const getMediaFromClipboardToSend = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }>(
        'media/sendMedia', async (_, thunkApi) => {
            const { getState, dispatch } = thunkApi;
            const state = getState();

            const user = selectUser(state);
            if (!user) {
                dispatch(accessFormPopupActions.setStatus('Login'));
                return;
            }

            try {
                const mediaObj = await ContentMessageServiceActions.getMedia();
                const currentChat = state.WhatsAppReducer.currentChat;
                if (!currentChat) {
                    throw new Error('No chat selected');
                }

                let image: File | undefined;
                let imageId: string | undefined;

                if (mediaObj?.mediaBlobList?.[0]) {
                    const mediaDescriptor = mediaObj.mediaBlobList[0];
                    const blobData = mediaDescriptor.file instanceof Blob || mediaDescriptor.file instanceof File
                        ? mediaDescriptor.file
                        : mediaDescriptor.file?.blob || mediaDescriptor.file?._blob;

                    if (!blobData) {
                        throw new Error('Invalid media format');
                    }

                    image = new File([blobData], mediaDescriptor.filename || '', { type: mediaDescriptor.mimetype });
                    imageId = randomstring.generate(10);
                    blobMap.set(imageId, image);
                }

                const contact = {
                    ...currentChat,
                    name: currentChat?.displayName ?? currentChat?.contact?.displayName ?? 'Unknown',
                    id: currentChat?.id?._serialized
                };

                // DO NOT REMOVE THE setTimeout - WITHOUT IT SOMETIMES THE WHATSAPP IS STUCK
                setTimeout(() => {
                    console.log("open editMessage with image")
                    dispatch(addScheduledMessageButtonActions.editMessage({
                        message: mediaObj?.caption,
                        imageId,
                        contactList: [contact],
                        dueDate: moment().toISOString(),
                        isRecurring: false,
                        state: MessageState.editing
                    }));
                }, 1000);
            } catch (error) {
                console.error('Failed to process media:', error);
                throw error;
            }
        }
    );


export const getChatsAndContactsThunk = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>(
    'whatsapp/getChatsAndContactsThunk', async (_, thunkApi) => {
        const { getState, dispatch } = thunkApi
        const state = getState()
        const user = selectUser(state)


        if (!user) {
            dispatch(accessFormPopupActions.setStatus('Login'));
            return;
        }

        const chats = state.WhatsAppReducer.chatList;
        const contacts = state.WhatsAppReducer.contactList;

        if (chats.length === 0 && contacts.length === 0) {
            const chatsAndContacts: any = await ContentMessageServiceActions.getChatsAndContacts();
            dispatch(setWAcontacts(chatsAndContacts?.contactList ?? []));
            dispatch(setWAchats(chatsAndContacts?.chatList ?? []));
        }



    }
);


