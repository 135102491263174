import { ReactComponent as WaGroupSVG } from '@common-assets/waGroup.svg';
import { ReactComponent as WaPersonSVG } from '@common-assets/waPerson.svg';
import RowStack from '@common-components/common/row-stack/RowStack';
import PersonAvatar from '@common-components/tasks/person-avatar/person-avatar';
import { MessageLog } from '@common-models/message-log';
import { closeBulkDashboard } from '@common-reducers/CampaignReducer';
import { messageLogSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { selectMergedContactMap } from '@common-reducers/WhatsAppReducer';
import { calculatedName, selectProfilePic } from '@common-reducers/WhatsAppSelectors';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { getProfilePicUrlThunk } from '@extension/context/content/thunks/WhatsappThunks';
import { Stack } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { ContentMessageServiceActions } from '../../services/contentMessageServiceActions';
import { StatusBadge } from './status-labels';



type Props = {
    itemId: string;
    index: number;
}

export default function LogDialogRow({
    itemId,
    index,
}: Props) {
    const dispatch = useAppDispatch();
    const rowData = useAppSelector((state) => messageLogSelectOneObjectByQuery(state.DBMessageLogReducer, {
        $or: [
            { deleted: { $exists: false } },
            { deleted: false }
        ],
        _id: itemId,
    }))
    const mergedContactMap = useAppSelector((state) => selectMergedContactMap(state));


    const whatsappId = rowData?.message?.contactList?.[0]?.id
    const isGroup = whatsappId?.includes('@g.us')
    const profilePicUrl = useAppSelector((state) => selectProfilePic(state, whatsappId));

    useEffect(() => {
        dispatch(getProfilePicUrlThunk(whatsappId));
    }, [dispatch, whatsappId]);



    const contactPhone = (contact: any) => {
        if (contact?.id.includes('@c.us')) {
            const phone = contact?.id?.replace('@c.us', '');
            return phone ? ` +${phone}` : '';
        }
        return ''
    }

    function getStatusChip(status) {
        switch (status) {
            case 'cancelled':
                return <> {StatusBadge('Cancelled')} </>;
            case 'sent':
                return <> {StatusBadge('Sent')} </>;
            case 'read':
            default:
                return <>  {StatusBadge(status)} </>;
        }
    }


    const statusField = (messageLog: MessageLog) => {
        let _statusField;
        const status = messageLog.status

        switch (messageLog.status) {
            case 'pending':
            case 'cancelled':
            case 'read':
            case 'sent': {
                _statusField = (
                    <Stack sx={{ justifyContent: 'end' }}>
                        <RowStack sx={{ fontWeight: 400 }} gap={2}>
                            <span>{moment(new Date(messageLog.createdAt)).format('DD/MM/YYYY HH:mm:ss')}</span>
                            {getStatusChip(status)}
                        </RowStack>
                    </Stack>
                );
                break;
            }
        }

        return _statusField;
    }



    const focusWAContact = () => {
        const contactId = rowData?.message?.contactList?.[0]?.id;
        if (contactId) {
            ContentMessageServiceActions.focusContact(contactId)
            dispatch(closeBulkDashboard());
        }
    };

    return (
        <RowStack
            key={index}
            onClick={focusWAContact}
            sx={{
                p: '12px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                cursor: 'pointer',
                justifyContent: 'space-between',
            }}>

            <RowStack gap={2}>
                {profilePicUrl ? (
                    <PersonAvatar
                        textSize={16}
                        size={55}
                        profileImg={profilePicUrl}
                        lastName={calculatedName(mergedContactMap?.[rowData?.message?.contactList?.[0]?.id])?.split(' ')[1] ?? ''}
                        firstName={calculatedName(mergedContactMap?.[rowData?.message?.contactList?.[0]?.id])?.split(' ')[0] ?? ''}
                        bgcolor='#42d871'
                    />
                ) : isGroup ? (
                    <WaGroupSVG />
                ) : (
                    <WaPersonSVG />
                )}


                {calculatedName(rowData?.message?.contactList?.[0])}
                {contactPhone(rowData?.message?.contactList?.[0])}
            </RowStack>



            <span>{statusField(rowData)}</span>

        </RowStack>
    )
}