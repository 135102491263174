import RowStack from "@common-components/common/row-stack/RowStack"
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook"
import { getRelevantMessageLogsByCampaignId } from "@common/reducers/CampaignSelectors"
import { getCampaignMessagesAndLogs } from "@common/reducers/CampaignThunks"
import { campaignSelectors } from "@common-reducers/DBServiceThunks"
import { Box, Chip, LinearProgress, Stack, SxProps } from "@mui/material"
import { useEffect } from "react"

type Props = {
    campaignId: string
    showProgressBar?: boolean
    sx?: SxProps
    onClick?: () => void
}

export const InProgressCampaignIndicator = ({
    campaignId,
    showProgressBar,
    sx = {},
    onClick
}: Props) => {
    const dispatch = useAppDispatch()

    const campaign = useAppSelector(state => campaignSelectors.selectById(state, campaignId))
    const relevantMessageLogs = useAppSelector(state => getRelevantMessageLogsByCampaignId(state, campaignId))


    useEffect(() => {
        console.log("get campaigns logs");
        dispatch(getCampaignMessagesAndLogs(campaignId))
    }, [campaignId, dispatch])

    return (
        <Stack key={campaignId} spacing={0.5}
            onClick={onClick}
            sx={{
                cursor: 'pointer',
            }}>
            <RowStack spacing={1}>
                {<Chip sx={{ cursor: 'pointer', ...sx }} label={`${relevantMessageLogs.length}/${campaign.contactCount} sent`} />}
            </RowStack>
            {showProgressBar && <LinearProgress />}
        </Stack>
    )
}

export default InProgressCampaignIndicator