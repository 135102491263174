import { Stack, Typography, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from 'react';

interface ContactCellProps {
    contact: {
        id?: string;
        name?: string;
    };
}

export const ContactCell = ({ contact }: ContactCellProps) => {
    const [copiedId, setCopiedId] = useState<string | null>(null);

    const handleCopy = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (contact.id) {
            navigator.clipboard.writeText(contact.id);
            setCopiedId(contact.id);
            setTimeout(() => setCopiedId(null), 2000);
        }
    };

    if (!contact?.id) return null;

    const displayText = contact.name || contact.id;

    return (
        <Stack 
            direction="row" 
            spacing={1} 
            alignItems="center"
            sx={{
                maxWidth: '200px',
                minWidth: '200px',
                width: '200px'
            }}
        >
            <Tooltip title={displayText}>
                <Typography 
                    variant="body2"
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        flex: 1
                    }}
                >
                    {displayText}
                </Typography>
            </Tooltip>
            <Tooltip title={copiedId === contact.id ? "Copied!" : "Copy ID"}>
                <IconButton
                    size="small"
                    onClick={handleCopy}
                    color={copiedId === contact.id ? "success" : "default"}
                    sx={{ flexShrink: 0 }}
                >
                    <ContentCopyIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}; 