import { Chip, Stack, IconButton, Tooltip } from '@mui/material';
import { MessageStatus } from '@common/models/user-message';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from 'moment';

// Unified type to handle all possible statuses
type AllMessageStatus = MessageStatus | 'delivered' | 'read' | 'before-wa-send' | 'bt-sent' | 'played' | 'cancelled' | 'error';

interface StatusCellProps {
    status: AllMessageStatus;
    dueDate?: string | Date;
}

const getStatusColor = (status: AllMessageStatus) => {
    switch (status) {
        // Success states - green shades
        case 'sent':
        case 'bt-sent':
            return '#4CAF50';  // Light green
        case 'delivered':
            return '#2E7D32';  // Medium green
        case 'read':
        case 'played':
            return '#1B5E20';  // Dark green
            
        // Pending/Processing states - orange/blue
        case 'pending':
            return '#FFA726';  // Orange
        case 'sending':
        case 'before-wa-send':
            return '#42A5F5';  // Light blue
            
        // Success confirmation
        case 'confirmed':
            return '#2E7D32';  // Medium green
        case 'received':
            return '#1B5E20';  // Dark green
            
        // Error states - red shades
        case 'removed':
        case 'cancelled':
        case 'error':
            return '#D32F2F';  // Red
            
        // Default
        default:
            return '#757575';  // Grey
    }
};

export const StatusCell = ({ status, dueDate }: StatusCellProps) => {
    const isFutureMessage = dueDate && moment(dueDate).isAfter(moment());

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Chip 
                label={status}
                size="small"
                sx={{
                    backgroundColor: getStatusColor(status),
                    color: 'white',
                    fontWeight: 'bold',
                    textTransform: 'capitalize'
                }}
            />
            {isFutureMessage && (
                <Tooltip title={moment(dueDate).format('MMM D, HH:mm')}>
                    <IconButton 
                        size="small" 
                        sx={{ 
                            color: '#9C27B0',
                            padding: '2px'
                        }}
                    >
                        <AccessTimeIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
        </Stack>
    );
}; 