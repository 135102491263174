import { TaskColumn } from "@common-models/task-column";
import { UserInterface } from "@common-models/user";
import { createDeepEqualSelector } from "@common-services/deep-equal-selector";
import { createSelector } from "@reduxjs/toolkit";

import { isMessageOverDue, isScheduleValid } from "@common-services/utils";

import { MessageLog, MessageStatusList } from "@common/models/message-log";
import { CommonRootState } from "@common/types/common-root-state-type";
import { boardsSelectByQuery, campaignsSelectByQuery, campaignsSelectOneObjectByQuery, isTempId, messageLogSelectByQuery, taskColumnSelectByQuery, userMessagesSelectByQuery } from "@common-reducers/DBServiceThunks";
import { activeSubscriptionSelector, selectUser } from "./UserSelectors";
import { selectMergedContactMap } from "./WhatsAppReducer";
import moment from "moment";

type MessageLogStatus = typeof MessageStatusList[number];

export const getUserIdIfExistForBulkDashboard = createSelector(
    [
        (state) => state
    ],
    (state) => {
        const user = selectUser as UserInterface

        return user._id;
    }
);


export const getIfSubscriptionHasBulk = createSelector(
    [
        (state) => state
    ],
    (state) => {
        const activeSubscription = activeSubscriptionSelector(state)

        return activeSubscription?.hasBulk;
    }
);


export const getSelectedListId = createSelector(
    [
        (state) => state
    ],
    (state) => {
        const { contactListPanelState: contactListPanel } = state.CampaignReducer;

        return contactListPanel?.getSelectedListId;
    }
);


export const getSelectedTemplateId = createSelector(
    [
        (state) => state
    ],
    (state) => {
        const { templatePanelState } = state.CampaignReducer;

        return templatePanelState?.selectedTemplateId;
    }
);


export const getTemplatePlaceholderListForCampaign = createSelector(
    [
        (state, boardIDArray) => state,
        (state, boardIDArray) => boardIDArray,
    ],
    (state, boardIDArray) => {
        let columnList = []
        for (let boardId of boardIDArray ?? []) {
            const currentBoardIdColumnList = taskColumnSelectByQuery(
                state.DBTaskColumnReducer,
                {
                    $or: [{ deleted: { $exists: false } }, { deleted: false }],
                    boardId,
                },
                ["type", "title"],
                {
                    order: 1,
                }
            )
                .filter(column => ['number', 'link', 'text-cell', 'phone-cell', 'option-picker', 'complexity-picker',
                    'status-option-picker', 'datepicker',].includes(column.type)) // in case not all column types are supported
            columnList = [...columnList, ...currentBoardIdColumnList]
        }
        const seen = new Set();
        let returnList: string[] = columnList
            .map((column: TaskColumn) => column.title ?? column.type)
            .filter(str => {
                const normalizedStr = str.replace(/\s+/g, '').toLowerCase();
                if (seen.has(normalizedStr)) {
                    return false;
                }
                seen.add(normalizedStr);
                return true;
            })

        returnList.unshift('Whatsapp name')

        return returnList
    }
);



export const getTaskSumByBoardIdRecord = createSelector(
    [
        (state) => state,
    ],
    (state) => {
        const boards = boardsSelectByQuery(
            state.DBBoardsReducer,
            {
                $or: [{ deleted: { $exists: false } }, { deleted: false }],
                type: 'audience',
            },
            ["_id", "tasksCount"],
            {
                order: 1,
            }
        )

        let tasksRecord = {}

        for (let board of boards) {
            tasksRecord[board._id] = board.tasksCount
        }

        return tasksRecord
    }
);


export const getBoardNameByBoardIdRecord = createSelector(
    [
        (state) => state,
    ],
    (state) => {
        const boards = boardsSelectByQuery(
            state.DBBoardsReducer,
            {
                $or: [{ deleted: { $exists: false } }, { deleted: false }],
                type: 'audience',
            },
            ["_id", "name"],
            {
                order: 1,
            }
        )

        let namesRecord = {}

        for (let board of boards) {
            namesRecord = {
                ...namesRecord,
                [board._id]: board.name
            }
        }

        return namesRecord
    }
);


export const getCampaignSelectedAudenicesAmount = createSelector([(state) => state],
    (state) => {
        const newCampaign = state.CampaignReducer.newCampaign

        return (newCampaign?.boardIDArray?.length ?? 0)
    });


export const getCampaignDueDate = createSelector([(state) => state], (state) => {
    const newCampaign = state.CampaignReducer.newCampaign
    return newCampaign?.schedule?.dueDate
});


export const getIsCampaginMessageExist = createSelector([(state) => state], (state) => {
    const currentTemplate = state.CampaignReducer.newCampaign?.template
    const isCampaginMessageExist = currentTemplate?.message ||
        currentTemplate?.linkPreview?.url ||
        currentTemplate?.linkPreview?.title ||
        currentTemplate?.linkPreview?.description

    return isCampaginMessageExist
}
);



export const getIsAudienceRowChecked = createSelector(
    [
        (state, itemId) => state,
        (state, itemId) => itemId,
    ],
    (state, itemId) => {
        // const checked = newCampaign?.boardIDArray?.includes(itemId)
        const newCampaign = state.CampaignReducer.newCampaign

        return (newCampaign?.boardIDArray ?? []).includes(itemId)
    }
);


export const getIsCampaignWithoutContacts = createSelector(
    [
        (state) => state,
    ],
    (state) => {
        const taskSumByBoardIdRecord = getTaskSumByBoardIdRecord(state)
        const newCampaign = state.CampaignReducer.newCampaign

        let counter = 0
        for (let boardId of (newCampaign?.boardIDArray ?? [])) {
            if (boardId) {
                counter += taskSumByBoardIdRecord[boardId]
            }
        }

        return counter === 0
    }
);


export const getFilteredIdArray = createSelector(
    [
        (state, searchTerm) => state,
        (state, searchTerm) => searchTerm,
    ],
    (state, searchTerm) => {
        const mergedContactMap = selectMergedContactMap(state);
        const campaignIdForLogsView = state.CampaignReducer.campaignIdForLogsView
        const filteredKeys = Object.keys(mergedContactMap)
            .filter(key => mergedContactMap[key]?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()))

        const messageLogs = messageLogSelectByQuery(
            state.DBMessageLogReducer,
            {
                campaignId: campaignIdForLogsView,
            },
            [],
            {
                order: 1,
            }
        )

        const filteredMessageLogs = messageLogs.filter(log => log?.message.contactList?.[0]?.id &&
            filteredKeys.includes(log?.message.contactList?.[0]?.id))
            .map(log => log._id)

        return filteredMessageLogs
    }
);


export const getIsCampaignCompleteAndAllLogsAreComplete = createSelector(
    [
        (state, campaignId) => state,
        (state, campaignId) => campaignId,
    ],
    (state, campaignId) => {
        const campaignLogs = messageLogSelectByQuery(state.DBMessageLogReducer, {
            $or: [
                { deleted: { $exists: false } },
                { deleted: false }
            ],
            campaignId,
        })
        const campaign = campaignsSelectOneObjectByQuery(state.DBCampaignReducer, {
            _id: campaignId,
        })

        const numberOfLogs = campaignLogs?.length
        const numberOfSentLogs = campaignLogs?.filter(log => log.status === 'sent')?.length
        const isCampaignComplete = campaign.campaignStatus === "completed"

        return isCampaignComplete && (numberOfLogs === numberOfSentLogs)
    }
);

export const selectNumberOfAudienceSelected = createSelector(
    [
        (state) => state.CampaignReducer.newCampaign
    ],
    (newCampaign) => {
        return newCampaign?.boardIDArray?.length ?? 0;
    }
);

export const selectCanContinueToNextTab = createSelector(
    [
        selectNumberOfAudienceSelected,
        (state) => state.CampaignReducer.activeTab,
        (state) => state.CampaignReducer.newCampaign
    ],
    (isAudienceSelected, activeTab, newCampaign) => {
        const currentTemplate = newCampaign?.template

        const isPollValid = () => {

            function haveSimilarOptions() {
                const options = (currentTemplate?.poll?.options ?? []).filter((option) => option.text !== '');
                const uniqueOptions = [...new Set(options.map((option) => option.text))];
                return options.length !== uniqueOptions.length;
            }


            return (currentTemplate?.isPoll &&
                currentTemplate?.poll?.question?.length > 0 &&
                currentTemplate?.poll?.options?.length > 1 &&
                currentTemplate?.poll?.options?.filter(o => o.text.length > 0).length > 1 &&
                !haveSimilarOptions())
        }

        const isMessageExist = !currentTemplate?.isPoll && (
            currentTemplate?.message ||
            currentTemplate?.linkPreview?.url ||
            currentTemplate?.linkPreview?.title ||
            currentTemplate?.linkPreview?.description ||
            currentTemplate?.asset?.type?.includes('audio')
        )

        const isCampaignMessageExist = isMessageExist || isPollValid()

        const isCampaignDueDateExist = !!newCampaign?.schedule?.dueDate


        let canContinueToNextTab = false;

        switch (activeTab) {
            case "audiences":
                canContinueToNextTab = !!isAudienceSelected;
                break;
            case "messageAndFiles":
                canContinueToNextTab = isCampaignMessageExist;
                break;
            case "timeAndDate":
                canContinueToNextTab = isCampaignDueDateExist;
                break;
            default:
                break;
        }

        return canContinueToNextTab;
    }
);

export const selectIsTimingValid = createSelector([(state) => state.CampaignReducer.newCampaign],
    (newCampaign) => {
        const isNow = newCampaign?.schedule?.isNow;

        return isNow || isScheduleValid(newCampaign.schedule?.dueDate);
    }
);


export const getRunningCampaigns = createSelector(
    [(state) => state.DBCampaignReducer],
    (DBCampaignReducer) => {
        const runningCampaigns = campaignsSelectByQuery(DBCampaignReducer, {
            campaignStatus: { $in: ['running', 'pending', 'completed'] },
            contactCount: { $exists: true }
        }, [], { createdAt: -1 })
        return runningCampaigns;
    }
);

export const isAnyCampaignInProgressSelector = createSelector(
    [getRunningCampaigns],
    (getRunningCampaigns) => {

        const isAnyCampaignInProgressSelectorRes = getRunningCampaigns.length > 0
        return isAnyCampaignInProgressSelectorRes
    }
);

// getRelevantMessageLogsByCampaignId as a selector
export const getRelevantMessageLogsByCampaignId = createSelector(
    [(state) => state.DBMessageLogReducer, (_, campaignId) => campaignId],
    (DBMessageLogReducer, campaignId) => {
        const campaignSortedFilteredMessageLogs = messageLogSelectByQuery(
            DBMessageLogReducer,
            {
                campaignId,
                $or: [
                    { waMessageKey: { $exists: true } },
                    { status: "cancelled" }
                ]
            },
            [],  // No projection fields specified; adjust if needed
            { createdAt: -1 }  // Sort by `createdAt` in descending order
        );

        const groupedLogs = campaignSortedFilteredMessageLogs.reduce((uniqueLogsByMessageId, log) => {
            const { messageId } = log;

            // Store the log if it’s the first one for this messageId or if it has waMessageKey while the existing entry does not
            if (!uniqueLogsByMessageId[messageId] || uniqueLogsByMessageId[messageId].waMessageKey === undefined) {
                uniqueLogsByMessageId[messageId] = log;
            }

            return uniqueLogsByMessageId;
        }, {});

        // Convert the object to an array of logs
        return Object.values(groupedLogs ?? []) as MessageLog[];
    }
);

// getStuckMessagesByCampaignId as a selector
export const getStuckMessagesByCampaignId = createSelector(
    [(state) => state.DBUserMessageReducer, (_, campaignId) => campaignId], (DBUserMessageReducer, campaignId) => {
        const campaignPendingMessages = userMessagesSelectByQuery(DBUserMessageReducer, { campaignId }, [], { createdAt: -1 });

        return campaignPendingMessages.filter((message) => isMessageOverDue(message));
    }
);

// getRunningCampaignsWithLogsAndMessages as a selector
export const getRunningCampaignsWithLogsAndMessages = createSelector(
    [getRunningCampaigns, (state) => state],
    (runningCampaigns, state) => {
        return runningCampaigns.map((campaign) => {
            const campaignMessageLogs = getRelevantMessageLogsByCampaignId(state, campaign._id);
            const stuckMessages = getStuckMessagesByCampaignId(state, campaign._id);

            return {
                ...campaign,
                campaignMessageLogs,
                stuckMessages
            };
        });
    }
);

export const dateOfReference = new Date('2024-11-06T11:10:21.508271');

export const selectCampaignNumberOfContactsById = createDeepEqualSelector(
    [
        (state: CommonRootState, campaignId) => state.DBBoardsReducer,
        (state: CommonRootState, campaignId) => state,
        (state: CommonRootState, campaignId) => campaignId
    ],
    (DBBoardsReducer, state, campaignId) => {
        const campaign = campaignsSelectOneObjectByQuery(state.DBCampaignReducer, {
            _id: campaignId,
        })
        if (campaign.contactCount) return campaign.contactCount
        else {
            const taskSumByBoardIdRecord = getTaskSumByBoardIdRecord({ DBBoardsReducer })


            let counter = 0
            for (let boardId of (campaign?.boardIDArray ?? [])) {
                if (boardId) {
                    counter += taskSumByBoardIdRecord[boardId] ?? 0
                }
            }

            return counter
        }
    }
);

export const selectCampaignPendingMessageCountByCampaignId = createDeepEqualSelector(
    [
        (state: CommonRootState, campaignId) => campaignId,
        (state: CommonRootState, campaignId) => state,
    ],
    (campaignId, state) => {
        const pendingMessages = userMessagesSelectByQuery(state.DBUserMessageReducer, {
            campaignId,
            status: 'pending'
        })
        return pendingMessages.length
    }
)

export const selectCampaignNumberOfContactsByCampaignId = createDeepEqualSelector(
    [
        (state: CommonRootState, campaignId) => state.DBBoardsReducer,
        (state: CommonRootState, campaignId) => campaignId,
        (state: CommonRootState, campaignId) => state,
    ],
    (DBBoardsReducer, campaignId, state) => {
        const campaign = campaignsSelectOneObjectByQuery(state.DBCampaignReducer, { _id: campaignId });
        const taskSumByBoardIdRecord = getTaskSumByBoardIdRecord({ DBBoardsReducer })
        let counter = 0
        for (let boardId of (campaign?.boardIDArray ?? [])) {
            if (boardId) {
                counter += taskSumByBoardIdRecord[boardId] ?? 0
            }
        }

        return counter
    }
);

export const getCampaignsWithPendingMessagesIds = createDeepEqualSelector(
    [
        (state) => state,
    ],
    (state) => {
        const pendingMessages = userMessagesSelectByQuery(state.DBUserMessageReducer, {
            status: 'pending'
        });
        console.log('pendingMessages')
        console.log(pendingMessages)
        // create a unique list of all the message._id in messages
        const pendingMessagesCampaignIds = pendingMessages.filter(message => message.campaignId).map(message => message.campaignId);
        const uniqueCampaignIds = [...new Set(pendingMessagesCampaignIds)];

        return uniqueCampaignIds;
    }
);

export const selectCampaignLogs = createSelector(
    [
        (state) => state.DBMessageLogReducer,
        (state: CommonRootState, campaignId) => campaignId
    ],
    (messageLogState, campaignId) => {
        return messageLogSelectByQuery(messageLogState, {
            campaignId
        });
    }
);

export const getMesageLogAckByLogIdAndCampaignId = createDeepEqualSelector(
    [
        (state: CommonRootState, logId, campaignId) => state,
        (state: CommonRootState, logId, campaignId) => logId,
        (state: CommonRootState, logId, campaignId) => campaignId,
    ],
    (state: CommonRootState, logId, campaignId) => {
        const campaign = campaignsSelectOneObjectByQuery(state.DBCampaignReducer, { _id: campaignId })

        const ack = campaign.campaignStatistics?.[logId]

        if (ack) {
            return ack
        } else if (campaign.campaignStatus === 'completed-send') {
            return 3
        } else {
            return null
        }
    }
);


export const getNumberOfSentWithoutAck = createDeepEqualSelector(
    [
        (state, campaignId) => state,
        (state, campaignId) => campaignId,
    ],
    (state: CommonRootState, campaignId) => {
        const sentMessageLog = messageLogSelectByQuery(state.DBMessageLogReducer, { campaignId, status: 'sent' })

        const sentMessageLogWithAckMap = sentMessageLog.reduce((map, message) => {
            if (message.ack) {
                map[message.messageId] = message;
            }
            return map;
        }, {} as Record<string, any>);


        const sentMessageLogWithoutAckOrDuplicate = sentMessageLog.filter(message => {
            return !message.ack && !sentMessageLogWithAckMap[message.messageId];
        })
        return sentMessageLogWithoutAckOrDuplicate?.length ?? 0
    }
);

export const getNumberOfPendingWithoutAck = createDeepEqualSelector(
    [
        (state, campaignId) => state,
        (state, campaignId) => campaignId,
    ],
    (state: CommonRootState, campaignId) => {
        const pendingMessageLog = messageLogSelectByQuery(state.DBMessageLogReducer, { campaignId, status: 'pending' })

        const pendingMessageLogWithoutAckMap = pendingMessageLog.reduce((map, message) => {
            if (message.ack) {
                map[message.messageId] = message;
            }
            return map;
        }, {} as Record<string, any>);

        const pendingMessageLogWithoutAckOrDuplicate = pendingMessageLog.filter(message => {
            return !message.ack && !pendingMessageLogWithoutAckMap[message.messageId];
        })
        return pendingMessageLogWithoutAckOrDuplicate?.length ?? 0
    }
);

export const getNumberOfErrorWithoutAck = createDeepEqualSelector(
    [
        (state, campaignId) => state,
        (state, campaignId) => campaignId,
    ],
    (state: CommonRootState, campaignId) => {
        const errorMessageLog = messageLogSelectByQuery(state.DBMessageLogReducer, { campaignId, status: 'error' })
        const errorMessageLogWithoutAckMap = errorMessageLog.reduce((map, message) => {
            if (message.ack) {
                map[message.messageId] = message;
            }
            return map;
        }, {} as Record<string, any>);

        const errorMessageLogWithoutAckOrDuplicate = errorMessageLog.filter(message => {
            return !message.ack && !errorMessageLogWithoutAckMap[message.messageId];
        })
        return errorMessageLogWithoutAckOrDuplicate?.length ?? 0
    }

);



const getScheduledMessages = createSelector(
    [(state: CommonRootState) => state],
    (state) => {
        const scheduledMessages = Object.values(state.DBUserMessageReducer.entities).filter(
            (message) => !isTempId(message._id) && !message.deleted
        );
        return scheduledMessages ?? []
    }
);


const getStuckMessages = createSelector(
    [(state) => getScheduledMessages(state)],
    (userMessageList) => {

        let userMessageListFiltered = []

        for (const message of userMessageList) {

            const isPending = message.status === "pending" || message.status === undefined;
            const isOverDue = isMessageOverDue(message)

            if (isPending && isOverDue) {
                userMessageListFiltered.push(message)
            }
        }

        return userMessageListFiltered
    }
);

export const getCampaignStuckMessages = createSelector(
    [(state) => state, (_, campaignId) => campaignId],
    (state, campaignId) => {
        const stuckMessages = getStuckMessages(state);
        return stuckMessages.filter(message => message.campaignId === campaignId);
    }
);

export interface AggregatedMessageLog {
    messageId: string;
    latestStatus: MessageLogStatus;
    logCount: number;
    latestUpdate: string;
    logs: any[];
}

export interface MessageLogRow {
    id: string;
    messageId: string;
    message: any;  // Original message object
    latestStatus: MessageLogStatus;
    logCount: number | null;
    latestUpdate: string;
    isMainRow: boolean;
    dueDate?: string | Date;
}

export const selectAllMessageLogRows = createSelector(
    [
        (state: CommonRootState) => state.DBMessageLogReducer,
        (state: CommonRootState, userId: string) => userId,
        (state: CommonRootState, userId: string, expandedRows: { [key: string]: boolean }) => expandedRows,
    ],
    (messageLogState, userId, expandedRows): MessageLogRow[] => {
        const logs = messageLogSelectByQuery(messageLogState, {
            owner: userId || '',
            $or: [
                { deleted: { $exists: false } },
                { deleted: false }
            ]
        }, [], { 
            createdAt: -1
        });

        // First aggregate the logs
        const aggregatedLogs = Object.values(logs.reduce((acc: { [key: string]: AggregatedMessageLog }, log) => {
            if (!acc[log.messageId]) {
                acc[log.messageId] = {
                    messageId: log.messageId,
                    latestStatus: log.status as MessageLogStatus,
                    logCount: 1,
                    latestUpdate: log.createdAt,
                    logs: [log]
                };
            } else {
                acc[log.messageId].logs.push(log);
                acc[log.messageId].logCount++;
                const currentLatestTime = moment(acc[log.messageId].latestUpdate);
                const thisLogTime = moment(log.createdAt);
                if (thisLogTime.isAfter(currentLatestTime)) {
                    acc[log.messageId].latestStatus = log.status as MessageLogStatus;
                    acc[log.messageId].latestUpdate = log.createdAt;
                }
            }
            return acc;
        }, {}));

        // Then transform into rows with expansion logic
        return aggregatedLogs
            .sort((a, b) => moment(b.latestUpdate).valueOf() - moment(a.latestUpdate).valueOf())
            .flatMap(log => {
                // Find the latest log to get the message content
                const latestLog = log.logs.reduce((latest, current) => {
                    const currentTime = moment(current.createdAt);
                    const latestTime = moment(latest.createdAt);
                    return currentTime.isAfter(latestTime) ? current : latest;
                }, log.logs[0]);

                const mainRow: MessageLogRow = {
                    id: log.messageId,
                    messageId: log.messageId,
                    message: latestLog.message,
                    latestStatus: log.latestStatus as MessageLogStatus,
                    logCount: log.logCount,
                    latestUpdate: log.latestUpdate,
                    isMainRow: true,
                    dueDate: latestLog.message.dueDate
                };

                if (expandedRows[log.messageId]) {
                    const sortedLogs = [...log.logs].sort((a, b) => 
                        moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
                    );
                    
                    const subRows: MessageLogRow[] = sortedLogs.map((subLog, index) => ({
                        id: `${log.messageId}-${index}`,
                        messageId: '↳ ' + moment(subLog.createdAt).format('h:mm:ss A'),
                        message: subLog.message,
                        latestStatus: subLog.status as MessageLogStatus,
                        logCount: null,
                        latestUpdate: subLog.createdAt,
                        isMainRow: false,
                        dueDate: subLog.message.dueDate
                    }));
                    return [mainRow, ...subRows];
                }
                return [mainRow];
            });
    }
);

export const selectPaginatedMessageLogRows = createSelector(
    [
        (state: CommonRootState, userId: string, expandedRows: { [key: string]: boolean }) => 
            selectAllMessageLogRows(state, userId, expandedRows),
        (state: CommonRootState, userId: string, expandedRows: { [key: string]: boolean }, page: number) => page,
        (state: CommonRootState, userId: string, expandedRows: { [key: string]: boolean }, page: number, pageSize: number) => pageSize
    ],
    (allRows, page = 0, pageSize = 25): MessageLogRow[] => {
        const start = page * pageSize;
        const end = start + pageSize;
        return allRows.slice(start, end);
    }
);
