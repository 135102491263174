import { Box, Tooltip } from '@mui/material';

interface SessionStatusPillProps {
    status: string;
}

export function SessionStatusPill({ status }: SessionStatusPillProps) {
    const getStatusColor = (status: string) => {
        switch (status?.toLowerCase()) {
            case 'connected':
                return {
                    background: '#00c875',
                    color: '#fff'
                };
            case 'disconnected':
                return {
                    background: '#e44258',
                    color: '#fff'
                };
            case 'initializing':
            case 'browser_started':
            case 'navigating':
                return {
                    background: '#fdab3d',
                    color: '#fff'
                };
            case 'unpaired':
            case 'qrcode':
                return {
                    background: '#579bfc',
                    color: '#fff'
                };
            default:
                return {
                    background: '#c4c4c4',
                    color: '#fff'
                };
        }
    };

    const isValidStatus = [
        'disconnected',
        'initializing',
        'browser_started',
        'navigating',
        'unpaired',
        'qrcode',
        'connected'
    ].includes(status?.toLowerCase());

    const displayStatus = isValidStatus ? status : 'Unknown';

    return (
        <Tooltip title={`Status: ${displayStatus}`}>
            <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    px: 1.5,
                    py: 0.5,
                    borderRadius: '20px',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    ...getStatusColor(status)
                }}
            >
                {displayStatus}
            </Box>
        </Tooltip>
    );
} 