import mime from 'mime-types';
import moment from 'moment';
import { configManager } from './config/wcl.config';
import { UserMessage, WaMessageType } from "./wa-interfaces";

export function getWaMessageType(message: UserMessage): WaMessageType | undefined {
    const waCommunicationLayerConfig = configManager.getConfig()
    
    const contact = message.contactList?.[0];
    if (!contact || !contact.id) return;

    const isImageMessage = message.asset && isImage(message.asset);

    if (message.isPoll) {
        return 'poll'
    }

    if (isImageMessage) {
        return waCommunicationLayerConfig.WCL_ENV === 'server' ? 'serverImage' : 'image';
    }

    if (message.asset && !isImage(message.asset)) {
        return 'attachmentWithoutImage';
    }

    return 'text';
}

export function isImage(asset: any) {
    const mimeType = mime.lookup(asset.type ?? asset.name) || asset.type;
    return mimeType && mimeType.indexOf('image') > -1
}

export function categorizeMimeType(mimeType: string) {
    if (mimeType.startsWith('image')) return 'image';
    if (mimeType.startsWith('video')) return 'video';
    if (mimeType.startsWith('audio')) return 'audio';
    return 'document';
}

export function addHiddenDecryptedMessageIdToMessageText(text: string, messageId: string): string {
    const binaryMessageId = messageId
        .split('')
        .map(char => char.charCodeAt(0).toString(2).padStart(8, '0'))
        .join('');

    const encodedMessageId = binaryMessageId
        .split('')
        .map(bit => (bit === '0' ? '\u200B' : '\u200C'))
        .join('');

    return `${encodedMessageId}${text ?? ''}`;
}

export const initWaMessageOptions = (options: any) => {
    return {
        linkPreview: options.linkPreview === false ? undefined : true,
        sendAudioAsVoice: options.sendAudioAsVoice,
        sendVideoAsGif: options.sendVideoAsGif,
        sendMediaAsSticker: options.sendMediaAsSticker,
        sendMediaAsDocument: options.sendMediaAsDocument,
        caption: options.caption,
        quotedMessageId: options.quotedMessageId,
        parseVCards: options.parseVCards === false ? false : true,
        mentionedJidList: options.mentions || [],
        groupMentions: options.groupMentions,
        invokedBotWid: options.invokedBotWid,
        extraOptions: options.extra,
    };
}

export async function prepareMessageMentions(messageText: string, messageOptions: any) {
    const { messageTextWithMentions, messageOptionsWithMentionedIds } = replaceMentionsAndExtractIds(messageText, messageOptions);
    return { messageTextWithMentions, messageOptionsWithMentionedIds };
}

export function replaceMentionsAndExtractIds(messageText: string, options: any): { messageTextWithMentions: string, messageOptionsWithMentionedIds: any } {
    const pattern = /@\[(.*?)\]\((.*?)\)/g;
    const ids: string[] = [];

    const messageTextWithMentions = messageText?.replace(pattern, (match, display, id) => {
        ids.push(id);
        return `@${id.split('@')[0]}`;
    });

    const messageOptionsWithMentionedIds = { ...options, mentionedJidList: ids }
    return { messageTextWithMentions, messageOptionsWithMentionedIds };
}

export function prepareTextMessageWithReply(replyData: any, options: any) {
    if (replyData) {
        options = { ...options, quotedMessageId: replyData?._serialized };
    }
    return options;
}

export const isMessageSendAttemptedWas3MinutesAgo = (creationDate: string) => {
    const timeLimitInMinutes = 3
    if (moment().diff(moment(creationDate), 'minutes') < timeLimitInMinutes) {
        return false;
    }
    return true;
}

export const getThresholdTimestamp = (timeLimitInMinutes: number) => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const timeLimitInSeconds = timeLimitInMinutes * 60;
    return currentTimestamp - timeLimitInSeconds;
}

export const decodeZeroWidthMessageId = (encodedText: string): string => {
    if (!encodedText
        || (!encodedText.includes('\u200B') && !encodedText.includes('\u200C'))) {
        return "";  // No encoded data found
    }

    // Extract zero-width characters
    const zeroWidthData = encodedText.split('').filter(char => char === '\u200B' || char === '\u200C').join('');

    // Convert zero-width characters back to binary
    const binary = zeroWidthData.replace(/\u200B/g, '0').replace(/\u200C/g, '1');

    // Convert binary back to messageId string
    const decodedMessageId = binary?.match(/.{1,8}/g)
        ?.map(byte => String.fromCharCode(parseInt(byte, 2)))
        .join('') || ''; // Add fallback for null

    return decodedMessageId;
}

export const isUserMessageIdMatchToWaMessage = (messageId: string, waMessage: any): string | null => {
    if (!waMessage) return null;

    let decodedMessageId = null;
    if (waMessage.body) {
        decodedMessageId = decodeZeroWidthMessageId(waMessage.body);
        if (decodedMessageId === messageId) {
            return waMessage.id;
        }
    }

    if (waMessage.caption) {
        decodedMessageId = decodeZeroWidthMessageId(waMessage.caption);
        if (decodedMessageId === messageId) {
            return waMessage.id;
        }
    }

    if (waMessage.pollName) {
        decodedMessageId = decodeZeroWidthMessageId(waMessage.pollName);
        if (decodedMessageId === messageId) {
            return waMessage.id;
        }
    }

    return null;
}