import { CSSProperties } from 'react'


const style: CSSProperties = {
    borderRadius: '6px',
    fontSize: '13px',
    fontWeight: 600,
    display: 'flex', // Ensuring the content is aligned properly
    alignItems: 'center', // Center align items vertically
    justifyContent: 'center', // Center align items horizontally
    padding: '2px 7px 5px 7px',
    width: '70px',
}

export const StatusBadge = (status: string) => {


    switch (status) {

        case 'Sent':
        case 'Completed':
            return <div style={{
                color: 'var(--campaign-status-completed-text)',
                backgroundColor: 'var(--campaign-status-completed-bg)',
                ...style,
            }}>
                {status}
            </div>



        case 'Running':
            return <div style={{
                color: 'var(--campaign-status-running-text)',
                backgroundColor: 'var(--campaign-status-running-bg)',
                ...style,
            }}>
                {status}
            </div>

        case 'Cancelled':
        case 'Aborted':
            return <div style={{
                color: 'var(--campaign-status-aborted-text)',
                backgroundColor: 'var(--campaign-status-aborted-bg)',
                ...style,
            }}>
                {status}
            </div>

        case 'Paused':
            return <div style={{
                color: 'var(--campaign-status-paused-text)',
                backgroundColor: 'var(--campaign-status-paused-bg)',
                ...style,
            }}>
                {status}
            </div>

        case 'Pending':
        default:
            return <div style={{
                color: 'var(--campaign-status-pending-text)',
                backgroundColor: 'var(--campaign-status-pending-bg)',
                ...style,
            }}>
                {status}
            </div>

    }
}



