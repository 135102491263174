export interface PostMessageData { cmd: string }

export async function postMessageWithResponse<T>(
  data: PostMessageData & Record<string, any>,
  source: string = "messageService"
): Promise<T | undefined> {
  let timer: NodeJS.Timeout;

  return Promise.race([
    new Promise<T>((resolve, reject) => {
      const channel = new MessageChannel();

      console.log(`[postMessageWithResponse] [${source}] Send message ${data.cmd}`);

      // Handle postMessage response (for window.postMessage)
      channel.port1.onmessage = (event: MessageEvent) => {
        console.log(`[postMessageWithResponse] Response for : ${data.cmd}:`, event.data);
        clearTimeout(timer); // Clear the timeout when a response is received
        resolve(event.data);
      };

      // Send the message in the browser context
      try {
        if (typeof window !== "undefined" && window.postMessage) {
          window.postMessage(data, "*", [channel.port2]);
        } else {
          console.error(`[postMessageWithResponse] [${source}] No postMessage available`, data);
          reject();
        }
      } catch (e) {
        console.error(`[postMessageWithResponse] [${source}] Error: `, data);
        console.error(e);
        reject();
      }

    }),

    // Changed timeout to resolve instead of reject
    new Promise<undefined>((resolve) => {
      timer = setTimeout(() => {
        console.log(`[postMessageWithResponse] [${source}] No response received for cmd ${data.cmd}, continuing...`);
        resolve(undefined);
      }, 10000);
    }),
  ]).finally(() => {
    clearTimeout(timer);
  });
}

export async function postMessageToBackgroundWithResponse<T>(
  data: PostMessageData & Record<string, any>,
  source: string = "messageService"
): Promise<T | undefined> {
  let timer: NodeJS.Timeout;

  return Promise.race([
    new Promise<T>((resolve, reject) => {
      console.debug(`[Message Service] [postMessageToBackgroundWithResponse] [${source}] Send message ${data.cmd}`);

      try {
        // First try: Chrome extension context
        if (typeof chrome !== 'undefined' && chrome.runtime && chrome.runtime.sendMessage) {
          const sendMessage = (message: any) => {
            const callback = (response: any) => {
              // Check for common errors that indicate no extension is listening
              if (chrome.runtime.lastError) {
                const error = chrome.runtime.lastError.message;
                console.debug(`[Message Service] [${source}] Chrome runtime error: ${error}`);
                
                // If there's no listener or extension was uninstalled/disabled, resolve with undefined
                if (error.includes('receiving end does not exist') || 
                    error.includes('Could not establish connection')) {
                  clearTimeout(timer);
                  resolve(undefined);
                  return;
                }
                
                resolve(undefined);
              } else {
                clearTimeout(timer);
                resolve(response);
              }
            };

            if (chrome.runtime.id) {
              chrome.runtime.sendMessage(chrome.runtime.id, message, callback);
            } else {
              chrome.runtime.sendMessage(message, callback);
            }
          };
          sendMessage(data);
        } 
        // Second try: Web page context using window.postMessage
        else if (typeof window !== 'undefined') {
          const channel = new MessageChannel();
          
          channel.port1.onmessage = (event: MessageEvent) => {
            clearTimeout(timer);
            resolve(event.data);
          };

          window.postMessage(data, "*", [channel.port2]);
        } 
        else {
          console.warn(`[Message Service] [${source}] No messaging method available`, data);
          resolve(undefined); // Changed from reject to resolve(undefined)
        }
      } catch (e) {
        console.warn(`[Message Service] [${source}] Error: `, JSON.stringify(data)); // Changed from error to warn
        console.warn(e);
        resolve(undefined); // Changed from reject to resolve(undefined)
      }
    }),

    // Changed timeout to resolve instead of reject
    new Promise<undefined>((resolve) => {
      timer = setTimeout(() => {
        console.debug(`[Message Service] [${source}] No response received for cmd ${data.cmd}, continuing...`);
        resolve(undefined);
      }, 5000);
    }),
  ]).finally(() => {
    clearTimeout(timer);
  });
}

export async function getWaContactInfoFromListItemSelector(uniqueSelector) {
  let contactInfo = undefined;
  try {
    contactInfo = await postMessageWithResponse({ cmd: "getWaContactInfoFromListItemSelector", selector: uniqueSelector });
  } catch (error) {
    console.error("Error fetching contactInfo for uniqueSelector:", uniqueSelector, error);
  }
  return contactInfo;

}

