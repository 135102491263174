import { UserMessage } from '@common/models/user-message';
import FavoriteIcon from '@mui/icons-material/Favorite';
import RepeatIcon from '@mui/icons-material/Repeat';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { MediaPreviewIcon } from './MediaPreview';

interface MessageCellProps {
    message: UserMessage;
    onMediaClick: (message: UserMessage) => void;
}

export function MessageCell({ message, onMediaClick }: MessageCellProps) {
    const hasHeartEmoji = message.message?.match(/^[❤️🧡💛💚💙💜🤎🖤🤍]/);
    const messageContent = message.message || '';

    const getMessageIcon = () => {
        if (hasHeartEmoji || message.isPoll) {
            return (
                <FavoriteIcon 
                    fontSize="small" 
                    color="error"
                    sx={{ opacity: 0.7 }}
                    titleAccess="Marketing Message"
                />
            );
        }
        if (message.asset) {
            return <MediaPreviewIcon assetData={message.asset} />;
        }
        return null;
    };

    return (
        <Box sx={{ 
            width: '100%', 
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            display: 'flex',
            alignItems: 'center'
        }}>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%' }}>
                {/* Fixed width box for recurring icon */}
                <Box sx={{ width: 20, display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                    {message.isRecurring && (
                        <RepeatIcon 
                            fontSize="small" 
                            color="primary"
                            sx={{ opacity: 0.7 }}
                            titleAccess="Recurring Message"
                        />
                    )}
                </Box>

                {/* Fixed width box for media/heart icon */}
                <Box sx={{ width: 20, display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                    {getMessageIcon()}
                </Box>

                {/* Message Content */}
                <Box sx={{ 
                    flex: 1,
                    minWidth: 0, // Required for text-overflow to work
                    overflow: 'hidden'
                }}>
                    {message.asset && (
                        <Box 
                            onClick={() => onMediaClick(message)}
                            sx={{ 
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                mb: 0.5
                            }}
                        >
                            <Tooltip title={message.asset.name || 'Media attachment'}>
                                <Typography 
                                    variant="body2" 
                                    color="text.secondary" 
                                    sx={{ 
                                        fontStyle: 'italic',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '100%'
                                    }}
                                >
                                    {message.asset.name || 'Media attachment'}
                                </Typography>
                            </Tooltip>
                        </Box>
                    )}

                    {messageContent && (
                        <Tooltip title={messageContent}>
                            <Typography
                                variant="body2"
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    width: '100%'
                                }}
                            >
                                {messageContent}
                            </Typography>
                        </Tooltip>
                    )}

                    {message.isPoll && message.poll?.question && (
                        <Box sx={{ 
                            overflow: 'hidden',
                            mt: messageContent ? 0.5 : 0
                        }}>
                            <Tooltip title={`Poll: ${message.poll.question}`}>
                                <Typography 
                                    variant="body2" 
                                    color="text.secondary" 
                                    sx={{ 
                                        fontWeight: 500,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '100%'
                                    }}
                                >
                                    Poll: {message.poll.question}
                                </Typography>
                            </Tooltip>
                            {message.poll.options && message.poll.options.length > 0 && (
                                <Stack 
                                    direction="row" 
                                    spacing={0.5} 
                                    sx={{ 
                                        mt: 0.5,
                                        overflow: 'hidden'
                                    }}
                                >
                                    {message.poll.options.slice(0, 2).map((option, index) => (
                                        <Tooltip key={index} title={typeof option === 'string' ? option : option.text}>
                                            <Typography 
                                                variant="body2" 
                                                color="text.secondary"
                                                sx={{ 
                                                    bgcolor: 'rgba(0, 0, 0, 0.04)',
                                                    px: 0.5,
                                                    py: 0.25,
                                                    borderRadius: 0.5,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    maxWidth: '80px'
                                                }}
                                            >
                                                {typeof option === 'string' ? option : option.text}
                                            </Typography>
                                        </Tooltip>
                                    ))}
                                    {message.poll.options.length > 2 && (
                                        <Typography 
                                            variant="body2" 
                                            color="text.secondary"
                                            sx={{ opacity: 0.7 }}
                                        >
                                            +{message.poll.options.length - 2} more
                                        </Typography>
                                    )}
                                </Stack>
                            )}
                        </Box>
                    )}
                </Box>
            </Stack>
        </Box>
    );
} 