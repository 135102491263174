import { createSelector } from '@reduxjs/toolkit';
import { TestExtensionRootState } from '../../store/test-extenstion-store';
import { SessionEvent } from '../../models/session-event';
import { EntityState } from '@reduxjs/toolkit';

interface SessionEventsState extends EntityState<SessionEvent> {
  itemsLoading: boolean;
  itemsLoaded: boolean;
  total: number;
  skip: number;
  limit: number;
}

interface GroupedEvents {
  [key: string]: {
    latestEvent: SessionEvent;
    events: SessionEvent[];
  };
}

const getSessionEventsState = (state: TestExtensionRootState) => state.DBSessionEventsReducer as SessionEventsState;

export const selectPaginationStatus = createSelector(
  [getSessionEventsState],
  (state) => ({
    itemsLoading: state.itemsLoading,
    itemsLoaded: state.itemsLoaded,
    total: state.total,
    skip: state.skip,
    limit: state.limit
  })
);

export const selectSessionEventsByUserId = createSelector(
  [getSessionEventsState, (_state: TestExtensionRootState, userId?: string) => userId],
  (state, userId) => {
    if (!userId) return [];

    return Object.values(state.entities)
      .filter((event): event is SessionEvent => {
        if (!event) return false;
        if (!event.owner) return false;
        if (event.owner !== userId) return false;
        return true;
      });
  }
);

export const selectGroupedSessionEvents = createSelector(
  [selectSessionEventsByUserId],
  (events) => {
    return events.reduce((acc: GroupedEvents, event: SessionEvent) => {
      if (!event) return acc;
      
      const podId = event.details?.podId || event.podId || 'unknown';
      if (!acc[podId]) {
        acc[podId] = {
          latestEvent: event,
          events: []
        };
      }
      
      acc[podId].events.push(event);
      
      if (!acc[podId].latestEvent || 
          event.timestamp > acc[podId].latestEvent.timestamp) {
        acc[podId].latestEvent = event;
      }
      
      return acc;
    }, {});
  }
); 