import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { getCheckoutPageUrlThunk } from '@common-reducers/PaymentFormThunks';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material';
import { useState } from 'react';
import { CopyableText } from '../copyable-text';

interface GetCheckoutPageUrlProps {
    open: boolean;
    onClose: () => void;
    onError: (message: string) => void;
}

interface DebugInfo {
    function: string;
    input: any;
}

const PLANS = [
    { id: 'Basic', label: 'Basic' },
    { id: 'Standard', label: 'Standard' },
    { id: 'Pro', label: 'Pro' }
];

const DURATIONS = [
    { id: 'Monthly', label: 'Monthly' },
    { id: 'Yearly', label: 'Yearly' }
];

export function GetCheckoutPageUrl({ open, onClose, onError }: GetCheckoutPageUrlProps) {
    const [selectedPlan, setSelectedPlan] = useState('Basic');
    const [selectedDuration, setSelectedDuration] = useState('Monthly');
    const [checkoutUrl, setCheckoutUrl] = useState('');
    const [debugInfo, setDebugInfo] = useState<DebugInfo | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();

    const handleClose = () => {
        setCheckoutUrl('');
        setSelectedPlan('Basic');
        setSelectedDuration('Monthly');
        setDebugInfo(null);
        onClose();
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const planId = `${selectedPlan}-USD-${selectedDuration}`;
            const response = await dispatch(getCheckoutPageUrlThunk({
                item_price_id: planId,
                quantity: 1
            })).unwrap();
            
            if (response?.checkoutPageUrl) {
                setCheckoutUrl(response.checkoutPageUrl);
                if (response.debug) {
                    setDebugInfo(response.debug);
                }
            } else {
                onError('Invalid response from server');
            }
        } catch (error) {
            onError('Failed to get checkout URL');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Get Checkout Page</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, py: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel>Plan</InputLabel>
                        <Select
                            value={selectedPlan}
                            label="Plan"
                            onChange={(e) => setSelectedPlan(e.target.value)}
                        >
                            {PLANS.map(plan => (
                                <MenuItem key={plan.id} value={plan.id}>{plan.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <RadioGroup
                            row
                            value={selectedDuration}
                            onChange={(e) => setSelectedDuration(e.target.value)}
                        >
                            {DURATIONS.map(duration => (
                                <FormControlLabel
                                    key={duration.id}
                                    value={duration.id}
                                    control={<Radio />}
                                    label={duration.label}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>

                    {checkoutUrl && (
                        <Box sx={{ 
                            mt: 2,
                            p: 2,
                            bgcolor: 'grey.50',
                            borderRadius: 1,
                            border: '1px solid',
                            borderColor: 'grey.200'
                        }}>
                            <Typography variant="subtitle2" sx={{ mb: 1 }}>Checkout URL:</Typography>
                            <CopyableText text={checkoutUrl} />
                        </Box>
                    )}

                    {debugInfo && (
                        <Box sx={{ 
                            mt: 2,
                            p: 2,
                            bgcolor: 'grey.50',
                            borderRadius: 1,
                            border: '1px solid',
                            borderColor: 'grey.200'
                        }}>
                            <Typography variant="subtitle2" sx={{ mb: 1 }}>Debug Information:</Typography>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2">
                                    Function: hosted_pages.{debugInfo.function}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2" sx={{ mb: 1 }}>Input:</Typography>
                                <Box sx={{ 
                                    p: 2,
                                    bgcolor: 'grey.100',
                                    borderRadius: 1,
                                    fontFamily: 'monospace',
                                    position: 'relative',
                                    '& pre': {
                                        margin: 0,
                                        whiteSpace: 'pre-wrap',
                                        wordBreak: 'break-word',
                                        fontSize: '0.875rem',
                                        lineHeight: 1.5,
                                        maxHeight: '400px',
                                        overflow: 'auto'
                                    }
                                }}>
                                    <pre>{JSON.stringify(debugInfo.input, null, 2)}</pre>
                                    <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                                        <CopyableText text={JSON.stringify(debugInfo.input, null, 2)} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button 
                    onClick={handleSubmit} 
                    variant="contained"
                    disabled={isLoading || !!checkoutUrl}
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Generate URL'}
                </Button>
            </DialogActions>
        </Dialog>
    );
} 